import {
    NetworkRootStatistics,
    NetworkRootTemplate,
    NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import { RTT_LEVELS } from '@accumine/xbee-network-manager/const';
import { Col, Descriptions, Menu, Modal, Result, Row, Typography } from 'antd';
import { presetPalettes } from '@ant-design/colors';
import ForceGraph2D, {
    ForceGraphMethods,
    NodeObject,
} from 'react-force-graph-2d';
import { useEffect, useRef, useState } from 'react';
import MQTT from '../../util/mqtt';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNavigate, useParams } from 'react-router-dom';
import { Line } from '@ant-design/charts';
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

interface Node extends NodeObject {
    id: string;
    name: string;
    group: number;
    rtt: number;
}

const GatewayDiagnostics = ({
    network,
    mqtt,
}: {
    network: NetworkTemplate;
    mqtt: typeof MQTT;
}) => {
    const params = useParams();
    const gateway = network.roots.find(
        (r) => r.registeredId.v === params.registeredId
    );
    return (
        <>
            {gateway ? (
                <ShowDiagnosticInfoChild
                    gateway={gateway}
                    network={network}
                    mqtt={mqtt}
                />
            ) : (
                <Col span='19'>
                    <Result
                        status='404'
                        title="Gateway doesn't exist"
                        subTitle="The gateway you've selected does not exist. Please select another."
                    />
                </Col>
            )}
        </>
    );
};

const ShowDiagnosticInfoChild = ({
    network,
    gateway,
    mqtt,
}: {
    network: NetworkTemplate;
    gateway: NetworkRootTemplate;
    mqtt: typeof MQTT;
}) => {
    const navigate = useNavigate();
    const round = (val: number) => Math.round(val * 100) / 100;
    const getGraph = (network: NetworkTemplate) => {
        let graph = {
            nodes: [
                network.roots.map((root) => {
                    return {
                        id: root.nodeId.v,
                        group: 1,
                        name: root.displayName.v,
                    };
                }),
                network.nodes.map((node) => {
                    return { id: node.nodeId.v, group: 2, rtt: node.rtt.v };
                }),
            ].flat(10),
            links: [
                network.roots.map((root) =>
                    root.edges.map((edge) => {
                        return { source: root.nodeId.v, target: edge };
                    })
                ),
                network.nodes.map((node) => {
                    if (
                        !node.edges.some((e) =>
                            network.roots.some((r) => r.nodeId.v === e)
                        )
                    ) {
                        return node.edges.map((edge) => {
                            return { source: edge, target: node.nodeId.v };
                        });
                    } else {
                        return [];
                    }
                }),
            ].flat(10),
        };
        return graph;
    };

    const [menuKey, setMenuKey] = useState<number>(0);
    const [statistics, setStatistics] = useState<NetworkRootStatistics>({
        gatewayLoad: [],
        gatewayAverageLoad: null,
        gatewayCurrentLoad: null,
        gatewayTemperature: null,
        gatewayMemUsed: null,
        gatewayFileUsed: null,
        transceiverVoltage: null,
        transceiverTemperature: null,
        updatedOn: -1,
    });
    const fgRef = useRef<ForceGraphMethods>();
    const [graph, setGraph] = useState(getGraph(network));

    useEffect(() => {
        mqtt.router.on(
            `${localStorage['databaseId']}/edge/${gateway.registeredId.v}/statistics`,
            setStatistics
        );
        return () => {
            mqtt.router.removeListener(
                `${localStorage['databaseId']}/edge/${gateway.registeredId.v}/statistics`,
                setStatistics
            );
        };
    }, []);

    useEffect(() => {
        setGraph(getGraph(network));
        return () => {};
    }, [network]);

    return (
        <Modal
            visible={true}
            title='Diagnostic Information'
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={() => navigate(`/gateways/${gateway.registeredId.v}`)}
            onOk={() => navigate(`/gateways/${gateway.registeredId.v}`)}
            footer={null}
            width={window.innerWidth * 0.8}
        >
            <Menu theme='light' mode='horizontal' defaultSelectedKeys={['0']}>
                <Menu.Item key='0' onClick={() => setMenuKey(0)}>
                    Realtime Information
                </Menu.Item>
                <Menu.Item key='1' onClick={() => setMenuKey(1)}>
                    Network Load
                </Menu.Item>
            </Menu>
            <div
                style={{
                    height: window.innerHeight * 0.55,
                    overflow: 'auto',
                }}
            >
                {
                    {
                        0: (
                            <Descriptions bordered size='middle' column={1}>
                                <Descriptions.Item label='Last Updated On'>
                                    {statistics.updatedOn === -1
                                        ? statistics.updatedOn
                                        : dayjs(
                                              statistics.updatedOn
                                          ).calendar()}
                                </Descriptions.Item>
                                <Descriptions.Item label='CPU Average Load'>
                                    {statistics.gatewayAverageLoad !== null
                                        ? round(statistics.gatewayAverageLoad) +
                                          '%'
                                        : 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label='CPU Current Load'>
                                    {statistics.gatewayCurrentLoad !== null
                                        ? round(statistics.gatewayCurrentLoad) +
                                          '%'
                                        : 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label='CPU Temperature'>
                                    {statistics.gatewayTemperature !== null
                                        ? round(statistics.gatewayTemperature) +
                                          'C'
                                        : 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label='RAM Used'>
                                    {statistics.gatewayMemUsed !== null
                                        ? round(statistics.gatewayMemUsed) + '%'
                                        : 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label='Disk Used'>
                                    {statistics.gatewayFileUsed !== null
                                        ? round(statistics.gatewayFileUsed) +
                                          '%'
                                        : 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label='Transceiver Voltage'>
                                    {statistics.transceiverVoltage !== null
                                        ? round(statistics.transceiverVoltage) +
                                          'V'
                                        : 'N/A'}
                                </Descriptions.Item>
                                <Descriptions.Item label='Transceiver Temperature'>
                                    {statistics.transceiverTemperature !== null
                                        ? round(
                                              statistics.transceiverTemperature
                                          ) + 'C'
                                        : 'N/A'}
                                </Descriptions.Item>
                            </Descriptions>
                        ),
                        1: (
                            <Row
                                style={{
                                    width: '100%',
                                    marginTop: 10,
                                }}
                                gutter={[16, 24]}
                            >
                                <Col span={12}>
                                    <Typography.Text
                                        strong
                                        style={{
                                            fontSize: 20,
                                            float: 'left',
                                        }}
                                    >
                                        Network Load
                                    </Typography.Text>
                                </Col>
                                <Col span={12}>
                                    <Typography.Text
                                        style={{
                                            float: 'right',
                                        }}
                                        type='secondary'
                                    >{`Last updated ${dayjs(
                                        statistics.updatedOn
                                    ).toNow(true)} ago`}</Typography.Text>
                                </Col>
                                <Col span={24}>
                                    <Line
                                        height={window.innerHeight * 0.45}
                                        animation={false}
                                        padding='auto'
                                        xField='second'
                                        seriesField='category'
                                        yField='value'
                                        xAxis={{
                                            label: {
                                                formatter: (v) =>
                                                    dayjs(v).format(
                                                        'hh:mm:ss A'
                                                    ),
                                            },
                                        }}
                                        data={statistics.gatewayLoad
                                            .map((item, index) => {
                                                let flattened = [];
                                                flattened.push(
                                                    {
                                                        value: item.loss,
                                                        second: dayjs(
                                                            statistics.updatedOn
                                                        )
                                                            .subtract(
                                                                index,
                                                                'second'
                                                            )
                                                            .toISOString(),
                                                        category: `Lost Packets`,
                                                    },
                                                    {
                                                        value: item.fps,
                                                        second: dayjs(
                                                            statistics.updatedOn
                                                        )
                                                            .subtract(
                                                                index,
                                                                'second'
                                                            )
                                                            .toISOString(),
                                                        category:
                                                            'Publishes per Second',
                                                    }
                                                );
                                                return flattened;
                                            })
                                            .flat(10)
                                            .reverse()}
                                    />
                                </Col>
                            </Row>
                        ),
                        2: (
                            <ForceGraph2D
                                ref={fgRef}
                                graphData={graph}
                                width={window.innerWidth * 0.75}
                                height={window.innerHeight * 0.6}
                                nodeLabel={(baseNode: NodeObject) => {
                                    let node: Node = baseNode as Node;
                                    if (node.group === 1) {
                                        return `<div style="text-align:center">Gateway<br>${
                                            node.name
                                        }<br>${node.id.toUpperCase()}</div>`;
                                    } else {
                                        let strength: string;
                                        if (node.rtt === -1) {
                                            strength = 'Unknown';
                                        } else if (
                                            node.rtt <= RTT_LEVELS['EXCELLENT']
                                        ) {
                                            strength = 'Excellent';
                                        } else if (
                                            node.rtt <= RTT_LEVELS['GOOD']
                                        ) {
                                            strength = 'Good';
                                        } else if (
                                            node.rtt <= RTT_LEVELS['FAIR']
                                        ) {
                                            strength = 'Fair';
                                        } else {
                                            strength = 'Poor';
                                        }
                                        return `<div style="text-align:center">Sensorbot<br>${node.id.toUpperCase()}<br>Signal strength: ${strength}<br>Round-trip-time: ${
                                            node.rtt === -1
                                                ? 'N/A'
                                                : `${node.rtt}ms`
                                        }</div>`;
                                    }
                                }}
                                nodeColor={(baseNode: NodeObject) => {
                                    let node: Node = baseNode as Node;
                                    if (node.group === 1) {
                                        return presetPalettes.blue[7];
                                    } else {
                                        if (node.rtt <= 300) {
                                            return presetPalettes.green[6];
                                        } else if (node.rtt <= 600) {
                                            return presetPalettes.green[4];
                                        } else if (node.rtt <= 1000) {
                                            return presetPalettes.gold[6];
                                        } else {
                                            return presetPalettes.red[5];
                                        }
                                    }
                                }}
                                linkColor={() => 'rgba(0,0,0,1)'}
                                linkWidth={3}
                                linkCurvature={0.1}
                                linkDirectionalArrowLength={3}
                                linkDirectionalArrowRelPos={1}
                                enableNodeDrag={true}
                                cooldownTime={100}
                                onEngineStop={() => {
                                    if (fgRef !== undefined)
                                        (
                                            fgRef.current as ForceGraphMethods
                                        ).zoomToFit(400, 50);
                                }}
                            />
                        ),
                    }[menuKey]
                }
            </div>
        </Modal>
    );
};

export default GatewayDiagnostics;
