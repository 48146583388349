import {NetworkNodeTemplate} from '@accumine/xbee-network-manager/types';
import {RTT_LEVELS} from '@accumine/xbee-network-manager/const';
import {Progress, Tooltip} from 'antd';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const SensorBotSignal = ({sensorBot}: {sensorBot: NetworkNodeTemplate}) => {
  const [strength, setStrength] = useState(0);
  const [color, setColor] = useState('grey');
  const [message, setMessage] = useState('N/A');
  const [tooltip, setTooltip] = useState('');

  useEffect(() => {
    let strength = 0,
      color = 'grey',
      message = 'N/A',
      tooltip = '',
      length = sensorBot.rtts.v.length;
    if (length === 0) {
      strength = 0;
      color = 'grey';
      message = 'N/A';
      tooltip = 'Round trip time: Unknown';
    } else {
      let rtt = sensorBot.rtts.v[length - 1].rtt;
      if (rtt <= RTT_LEVELS['EXCELLENT']) {
        strength = 100;
        color = '#52c41a';
        message = 'Excellent';
      } else if (rtt <= RTT_LEVELS['GOOD']) {
        strength = 75;
        color = '#1890ff';
        message = 'Good';
      } else if (rtt <= RTT_LEVELS['FAIR']) {
        strength = 50;
        color = '#faad14';
        message = 'Fair';
      } else {
        strength = 25;
        color = '#f5222d';
        message = 'Poor';
      }
      tooltip = `Round trip time: ${rtt} ms`;
    }
    setStrength(strength);
    setColor(color);
    setMessage(message);
    setTooltip(tooltip);
  }, [sensorBot]);

  return (
    <Tooltip title={tooltip}>
      <Progress
        style={{marginTop: 5}}
        percent={strength}
        strokeColor={color}
        strokeWidth={20}
        steps={4}
        type="line"
        size="default"
        showInfo={true}
        format={(_) => `${message}`}
      />
    </Tooltip>
  );
};

export default SensorBotSignal;
