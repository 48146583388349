import {
  NetworkNodeTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {Button, Input, message, Modal, Result} from 'antd';
import {useState} from 'react';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useNavigate, useParams} from 'react-router-dom';
import {addEvent} from '../../util/networkManager';
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const AddNewNoteChild = ({sensorBot}: {sensorBot: NetworkNodeTemplate}) => {
  const navigate = useNavigate();
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [newNote, setNewNote] = useState('');

  const handleNoteAdd = async () => {
    setLoadingNotes(true);
    try {
      if (newNote !== '') {
        await addEvent({
          userId: localStorage.getItem('username') as string,
          deviceId: sensorBot.nodeId.v,
          type: 'note',
          description: newNote,
          timestamp: dayjs().utc().valueOf(),
        });
        setNewNote('');
        message.success('Note added.');
      }
    } catch (error) {
      message.error(`Could not add note: ${error}`);
    }
    setLoadingNotes(false);
  };

  return (
    <Modal
      visible={true}
      width={window.innerWidth * 0.4}
      title="Add Note"
      onCancel={() => navigate(-1)}
      onOk={() => {
        handleNoteAdd();
        navigate(-1);
      }}
      okText="Save"
      cancelText="Cancel"
      okButtonProps={{loading: loadingNotes}}
    >
      <Input.TextArea
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
        rows={4}
      />
    </Modal>
  );
};

const AddNewNote = ({network}: {network: NetworkTemplate}) => {
  const navigate = useNavigate();
  const params = useParams();
  const sensorBot = network.nodes.find((n) => n.nodeId.v === params.nodeId);
  return (
    <>
      {sensorBot ? (
        <AddNewNoteChild sensorBot={sensorBot} />
      ) : (
        <Modal
          visible={true}
          title="Add Note"
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}
        >
          <Result
            status="404"
            title="SensorBot doesn't exist"
            subTitle="The SensorBot you've selected does not exist."
            extra={
              <Button
                type="primary"
                onClick={() => navigate('/sensorbots?page=1')}
              >
                View all SensorBots
              </Button>
            }
          />
        </Modal>
      )}
    </>
  );
};

export default AddNewNote;
