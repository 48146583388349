import {
  DeviceEvent,
  NetworkRootTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {
  Avatar,
  Checkbox,
  Col,
  Divider,
  Modal,
  Result,
  Row,
  Space,
  Timeline,
  Typography,
} from 'antd';
import {useEffect} from 'react';
import {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useNavigate, useParams} from 'react-router-dom';
import {getEvents} from '../../util/networkManager';
import MQTT from '../../util/mqtt';
import dayjs from 'dayjs';
import {
  CloseCircleTwoTone,
  EditTwoTone,
  ExclamationCircleTwoTone,
  UserOutlined,
} from '@ant-design/icons';

const GatewayHistory = ({
  network,
  mqtt,
}: {
  network: NetworkTemplate;
  mqtt: typeof MQTT;
}) => {
  const params = useParams();
  const gateway = network.roots.find(
    (r) => r.registeredId.v === params.registeredId
  );

  return (
    <>
      {gateway ? (
        <GatewayHistoryChild gateway={gateway} mqtt={mqtt} />
      ) : (
        <Col span="19">
          <Result
            status="404"
            title="Gateway doesn't exist"
            subTitle="The gateway you've selected does not exist. Please select another."
          />
        </Col>
      )}
    </>
  );
};

const GatewayHistoryChild = ({
  gateway,
  mqtt,
}: {
  gateway: NetworkRootTemplate;
  mqtt: typeof MQTT;
}) => {
  const navigate = useNavigate();

  const [deviceHistory, setdeviceHistory] = useState([] as DeviceEvent[]);
  const [lastDate, setLastDate] = useState(dayjs().utc().valueOf());
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState(['event', 'error', 'note'] as string[]);

  const fetchEvents = async () => {
    if (loading) {
      return;
    }
    if (gateway) {
      setLoading(true);
      let events = await getEvents(gateway.registeredId.v, lastDate, 10);
      if (events && events.length !== 0) {
        setdeviceHistory(deviceHistory.concat(events));
        setLastDate(events[events.length - 1].timestamp);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    }
  };

  const handleNewEvent = (item: DeviceEvent) => {
    if (item.deviceId === gateway.registeredId.v) {
      deviceHistory.unshift(item);
      setdeviceHistory(deviceHistory);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (gateway) {
      mqtt.router.on(
        `${localStorage['databaseId']}/${localStorage['deviceHistoryDataModelId']}/create`,
        handleNewEvent
      );
      return () => {
        mqtt.router.removeListener(
          `${localStorage['databaseId']}/${localStorage['deviceHistoryDataModelId']}/create`,
          handleNewEvent
        );
      };
    }
  }, [deviceHistory]);

  return (
    <Modal
      width={window.innerWidth * 0.4}
      visible={true}
      title={
        <Row>
          <Col span={24}>
            <Typography.Title style={{float: 'left'}} level={4}>
              Device History
            </Typography.Title>
            <Checkbox.Group
              style={{
                float: 'right',
                marginTop: 5,
                marginRight: 20,
              }}
              options={[
                {label: 'Events', value: 'event'},
                {label: 'Errors', value: 'error'},
                {label: 'Notes', value: 'note'},
              ]}
              defaultValue={['event', 'error', 'note']}
              onChange={(checked) => {
                setHasMore(true);
                setFilter(checked as string[]);
              }}
            />
          </Col>
        </Row>
      }
      cancelButtonProps={{style: {display: 'none'}}}
      footer={null}
      onCancel={() => navigate(`/gateways/${gateway.registeredId.v}`)}
      onOk={() => navigate(`/gateways/${gateway.registeredId.v}`)}
    >
      <InfiniteScroll
        style={{marginTop: 10}}
        height={window.innerHeight * 0.5}
        dataLength={
          deviceHistory.filter((item) => filter.some((v) => v === item.type))
            .length
        }
        next={fetchEvents}
        hasMore={hasMore}
        loader=""
        endMessage={<Divider plain>Beginning of Device History</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <Timeline
          pending={loading ? 'Loading more history...' : undefined}
          mode="right"
        >
          <Timeline.Item key="now" color="green">
            Now
          </Timeline.Item>
          {deviceHistory
            .filter((item) => filter.some((v) => v === item.type))
            .map((item) => {
              let dot, content;
              if (item.type === 'error') {
                dot = <CloseCircleTwoTone twoToneColor={'#ff4d4f'} />;
              } else if (item.type === 'note') {
                dot = <EditTwoTone twoToneColor={'#8c8c8c'} />;
              } else {
                dot = <ExclamationCircleTwoTone twoToneColor={'#40a9ff'} />;
              }
              if (item.userId) {
                content = (
                  <>
                    <p style={{marginBottom: '0px'}}>{item.description}</p>
                    <Space>
                      <Avatar size={20} icon={<UserOutlined />} />
                      {item.userId.split('@')[0]}
                    </Space>
                  </>
                );
              } else {
                content = <p>{item.description}</p>;
              }
              return (
                <Timeline.Item
                  dot={dot}
                  label={dayjs(item.timestamp).format('LLLL')}
                >
                  {content}
                </Timeline.Item>
              );
            })}
        </Timeline>
      </InfiniteScroll>
    </Modal>
  );
};

export default GatewayHistory;
