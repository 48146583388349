import {NetworkNodeTemplate, NetworkTemplate} from '@accumine/xbee-network-manager/types';
import { wrap } from '@accumine/xbee-network-manager/util';
import {PlusOutlined} from '@ant-design/icons';
import {Divider, message, Select, Tooltip, Typography} from 'antd';
import dayjs from 'dayjs';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {AccumineCloudAsset} from '../../interfaces';
import {addEvent, updateSensorBot} from '../../util/networkManager';

const AssetSelector = ({
  network,
  assets,
  sensorBot,
}: {
  network: NetworkTemplate;
  sensorBot: NetworkNodeTemplate;
  assets: AccumineCloudAsset[];
}) => {
  const [edit, setEdit] = useState(false);
  const [assetSelectionLoading, setAssetSelectionLoading] = useState(false);
  return (
    <>
      {edit ||
      !assets.find((asset) => asset.deviceId === sensorBot.deviceId.v) ? (
        <Select
          disabled={sensorBot.locked.v || sensorBot.hide.v}
          showSearch={true}
          defaultValue={sensorBot.deviceId.v || undefined}
          loading={assetSelectionLoading}
          style={{width: '100%'}}
          allowClear={true}
          placeholder="Assign to asset..."
          filterOption={(input, option) => {
            let match = false;
            if (option) {
              if (
                option.label &&
                (option.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              ) {
                match = true;
              }
              if (
                option.value &&
                (option.value as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              ) {
                match = true;
              }
            }
            return match;
          }}
          onDropdownVisibleChange={(open: boolean) => {
            if (!open) {
              setEdit(false);
            }
          }}
          onChange={async (deviceId) => {
            setAssetSelectionLoading(true);
            try {
              sensorBot.deviceId = wrap((deviceId || '') as string, 'cloud');
              await updateSensorBot(sensorBot);
              await addEvent({
                userId: localStorage.getItem('username') as string,
                deviceId: sensorBot.nodeId.v,
                type: 'event',
                description: `User modified the device assignment to ${deviceId ? deviceId : 'none'}.`,
                timestamp: dayjs().utc().valueOf(),
              });
              message.success('Asset assignment saved.');
            } catch (error) {
              message.error(
                'Could not update asset assignment. Please try again.'
              );
            }
            setAssetSelectionLoading(false);
          }}
          bordered={false}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{margin: '4px 0'}} />
              <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                <Link
                  to={`/assets/create?sensorBotId=${sensorBot.nodeId.v}`}
                  style={{
                    flex: 'none',
                    padding: '8px',
                    display: 'block',
                    cursor: 'pointer',
                  }}
                >
                  <PlusOutlined /> Add Asset
                </Link>
              </div>
            </div>
          )}
        >
          {assets.map(asset => {
            let node = network.nodes.find(n => n.deviceId.v === asset.deviceId);
            let disabled = false;
            if (node && node.nodeId.v !== sensorBot.nodeId.v) {
              disabled = true;
            }
            return (
              <Select.Option
                disabled={disabled}
                value={asset.deviceId}
                label={asset.name}
              >
                <Tooltip
                  title={disabled ? 'Asset is already assigned.' : ''}
                >
                  {asset.name}
                </Tooltip>
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <Typography.Link
          editable={
            (sensorBot.locked.v || sensorBot.hide.v) ? false : {onStart: () => setEdit(true)}
          }
        >
          <Link
            to={`/assets/${sensorBot.deviceId.v}`}
          >
          {
            assets.find((asset) => asset.deviceId === sensorBot.deviceId.v)?.name || ''
          }
          </Link>

        </Typography.Link>
      )}
    </>
  );
};

export default AssetSelector;
