import {HEARTBEAT_INTERVAL, MAX_FPS, MAX_NODE_COUNT} from '@accumine/xbee-network-manager/const';
import {
  NetworkNodeTemplate,
  NetworkRootTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import { wrap } from '@accumine/xbee-network-manager/util';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {
  Button,
  List,
  message,
  Modal,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import GatewayStatus from '../../components/GatewayStatus';
import {addEvent, updateSensorBot} from '../../util/networkManager';

const GatewaySelector = ({
  network,
  sensorBot,
}: {
  network: NetworkTemplate;
  sensorBot: NetworkNodeTemplate;
}) => {
  const getGatewayStrengths = () => {
    let strengths = [];
    for (let id in sensorBot.rttg.v) {
      let gateway = network.roots.find(
        (gateway) => gateway.registeredId.v === id
      );
      if (gateway) {
        strengths.push({
          gateway: gateway.displayName.v
            ? gateway.displayName.v
            : gateway.registeredId.v,
          rtt: sensorBot.rttg.v[id],
          match: false,
        });
      }
    }
    if (strengths.length !== 0) {
      strengths.sort((a, b) => a.rtt - b.rtt);
      strengths[0].match = true;
    }
    return strengths;
  };

  const [edit, setEdit] = useState(false);
  const [gatewayStrengths, setGatewayStrengths] = useState(
    getGatewayStrengths()
  );
  const [gatewaySelectionLoading, setGatewaySelectionLoading] = useState(false);
  const [gatewayStrengthsModal, setGatewayStrengthsModal] = useState(false);

  useEffect(() => {
    setGatewayStrengths(getGatewayStrengths());
  }, [sensorBot]);

  return (
    <>
      {edit ||
      !network.roots.find(
        (gateway) => gateway.registeredId.v === sensorBot.gatewayId.v
      ) ? (
        <Select
          disabled={sensorBot.locked.v || sensorBot.hide.v}
          showSearch={true}
          defaultValue={sensorBot.gatewayId.v || undefined}
          loading={gatewaySelectionLoading}
          style={{width: '100%'}}
          allowClear={true}
          placeholder="Assign to gateway..."
          filterOption={(input, option) => {
            let match = false;
            if (option) {
              if (
                option.label &&
                (option.label as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              ) {
                match = true;
              }
              if (
                option.value &&
                (option.value as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              ) {
                match = true;
              }
            }
            return match;
          }}
          onDropdownVisibleChange={(open: boolean) => {
            if (!open) {
              setEdit(false);
            }
          }}
          onChange={async (gatewayId) => {
            setGatewaySelectionLoading(true);
            try {
              sensorBot.gatewayId = wrap((gatewayId || '') as string, 'cloud');
              await updateSensorBot(sensorBot);
              await addEvent({
                userId: localStorage.getItem('username') as string,
                deviceId: sensorBot.nodeId.v,
                type: 'note',
                description: `User modified the gateway assignment to ${gatewayId ? gatewayId : 'none'}.`,
                timestamp: dayjs().utc().valueOf(),
              });
              message.success(`Gateway assignment saved. It could take several minutes for the SensorBot to come online.`);
            } catch (error) {
              message.error(
                'Could not update Gateway assignment. Please try again.'
              );
            }
            setGatewaySelectionLoading(false);
          }}
          bordered={false}
        >
          {network.roots.map((g) => {
            let disabled =
              network.nodes.filter((n) => n.gatewayId.v === g.registeredId.v)
                .length >= MAX_NODE_COUNT || (g.fps.v + sensorBot.fps.v + 1000 / HEARTBEAT_INTERVAL) >= MAX_FPS;
            return (
              <Select.Option
                disabled={disabled}
                value={g.registeredId.v}
                label={g.displayName.v}
              >
                <Tooltip
                  title={disabled ? 'Gateway does not have enough resources.' : ''}
                >
                  <GatewayStatus gateway={g} iconSize={15} />{' '}
                  <div
                    style={{
                      marginLeft: 10,
                      display: 'inline-block',
                    }}
                  >
                    {g.displayName.v}
                  </div>
                </Tooltip>
              </Select.Option>
            );
          })}
        </Select>
      ) : (
        <Space align="center">
          <GatewayStatus
            gateway={
              network.roots.find(
                (gateway) => gateway.registeredId.v === sensorBot.gatewayId.v
              ) as NetworkRootTemplate
            }
            iconSize={15}
          />
          <Typography.Link
            editable={
              (sensorBot.locked.v || sensorBot.hide.v) ? false : {onStart: () => setEdit(true)}
            }
          >
            <Link
              to={`/gateways/${sensorBot.gatewayId.v}`}
            >
              {` ${
                network.roots.find(
                  (gateway) => gateway.registeredId.v === sensorBot.gatewayId.v
                )?.displayName.v  || ''
              }`}
            </Link>
          </Typography.Link>
          <Tooltip title="Show all gateway strengths.">
            <Button
              size="middle"
              type="text"
              icon={<QuestionCircleOutlined style={{fontSize: 15}} />}
              onClick={() => setGatewayStrengthsModal(true)}
            />
          </Tooltip>
        </Space>
      )}
      <Modal
        visible={gatewayStrengthsModal}
        title={'Gateway Reception Strength'}
        onCancel={() => setGatewayStrengthsModal(false)}
        cancelButtonProps={{style: {display: 'none'}}}
        okButtonProps={{style: {display: 'none'}}}
      >
        <List
          size="small"
          bordered
          dataSource={gatewayStrengths}
          renderItem={(item: {
            gateway: string;
            rtt: number;
            match: boolean;
          }) => {
            let rtt: string = 'Unknown.';
            if (item && item.rtt) {
              if (item.rtt !== Infinity) {
                rtt = `${item.rtt}  milliseconds.`;
              } else {
                rtt = 'Timed out.';
              }
            }
            if (item.match) {
              return (
                <Tooltip title="Best Choice.">
                  <List.Item style={{backgroundColor: 'lightgreen'}}>
                    <Typography.Text style={{float: 'left'}}>
                      {item.gateway}
                    </Typography.Text>
                    <Typography.Text style={{float: 'right'}}>
                      Round Trip Time: {rtt}
                    </Typography.Text>
                  </List.Item>
                </Tooltip>
              );
            } else {
              return (
                <List.Item>
                  <Typography.Text style={{float: 'left'}}>
                    {item.gateway}
                  </Typography.Text>
                  <Typography.Text style={{float: 'right'}}>
                    Round Trip Time: {rtt}
                  </Typography.Text>
                </List.Item>
              );
            }
          }}
        />
      </Modal>
    </>
  );
};

export default GatewaySelector;
