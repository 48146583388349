import {
  NetworkNodeTemplate,
  NodeState,
} from '@accumine/xbee-network-manager/types';
import {Typography} from 'antd';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const SensorBotUptime = ({sensorBot}: {sensorBot: NetworkNodeTemplate}) => {
  const [uptime, setUptime] = useState('N/A');

  const getUptime = (node: NetworkNodeTemplate) => {
    let newUptime: string;
    if (node.state.v.state === NodeState.OFFLINE) {
      newUptime = 'N/A';
    } else {
      newUptime =
        node.state.v.onlineOn !== -1
          ? dayjs
              .duration(dayjs().diff(dayjs(node.state.v.onlineOn)))
              .humanize()
          : 'N/A';
    }
    return newUptime;
  };

  useEffect(() => {
    setUptime(getUptime(sensorBot));
    let timer = setInterval(() => {
      setUptime(getUptime(sensorBot));
    }, 60000);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [sensorBot]);

  return <Typography.Text>{uptime}</Typography.Text>;
};

export default SensorBotUptime;
