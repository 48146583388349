import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Form,
    Input,
    Button,
    Layout,
    Row,
    Col,
    Spin,
    message,
    Typography,
    Image,
} from 'antd';
import { useEffect, useState } from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import ImageGif from '../../images/login-page-illustration.gif';
import Logo from '../../images/L2L_ConnectLogo_greenblack.png';

const Login = ({ onLogin }: { onLogin: any }) => {
    const [loading, setLoading] = useState(false);
    const onFinish = async (login: {
        username: string;
        password: string;
        factoryId: string;
    }) => {
        setLoading(true);
        try {
            const { data } = await axios.post(
                `${process.env.REACT_APP_API}/v2/users/login`,
                {
                    user: {
                        username: login.username,
                        factoryId: login.factoryId.trim().toLowerCase(),
                        password: login.password,
                    },
                }
            );
            if (data && data.error) {
                throw new Error(data.error);
            }
            localStorage['token'] = data.user.token;
            localStorage['factoryId'] = data.user.factoryId;
            localStorage['userObject'] = JSON.stringify(data.user);
            onLogin();
        } catch (error: any) {
            if (error.response && error.response.status === 500) {
                message.error(
                    'Could not login: Internal server error. Please try again.'
                );
            } else {
                message.error(
                    'Invalid factory ID, email, or password. Please try again.'
                );
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <Spin spinning={loading}>
            <Layout style={{ background: '#fff' }}>
                <Layout.Content
                    style={{ height: '100vh', overflow: 'auto', padding: 0 }}>
                    <Row align='middle' style={{ minHeight: '100vh' }}>
                        <Col
                            span='8'
                            style={{
                                textAlign: 'center',
                                padding: 20,
                                borderRight: '1px solid lightgrey',
                            }}>
                            <Image
                                style={{ textAlign: 'center' }}
                                src={Logo}
                                preview={false}
                            />
                            <Typography.Title
                                style={{ textAlign: 'center' }}
                                level={3}>
                                Edge
                            </Typography.Title>
                            <Typography.Title
                                style={{ textAlign: 'center' }}
                                level={5}
                                type='secondary'>
                                Sign In
                            </Typography.Title>
                            <Form
                                name='login'
                                layout='vertical'
                                onFinish={(values) => onFinish(values)}>
                                <Form.Item
                                    label='Factory ID'
                                    name='factoryId'
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your factory id!',
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label='Username'
                                    name='username'
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your username!',
                                        },
                                    ]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label='Password'
                                    name='password'
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input your password!',
                                        },
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                                <Row>
                                    <Col span='24'>
                                        <Form.Item>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                block>
                                                Sign In <ArrowRightOutlined />
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        <Col span='16' style={{ textAlign: 'center' }}>
                            <Image src={ImageGif} width={750} preview={false} />
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        </Spin>
    );
};

Login.propTypes = {
    onLogin: PropTypes.func.isRequired,
};

export default Login;
