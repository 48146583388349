import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import MQTT from './util/mqtt';
const mqtt = MQTT;

document.addEventListener("visibilitychange", () => {
  if (mqtt && mqtt.client && mqtt.client.disconnected) {
    mqtt.client.reconnect();
  }
});

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App mqtt={mqtt}/>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
