import {
  GatewayState,
  NetworkRootTemplate,
} from '@accumine/xbee-network-manager/types';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import {Spin, Tooltip} from 'antd';

const GatewayStatus = ({
  gateway,
  iconSize,
}: {
  gateway: NetworkRootTemplate;
  iconSize: number;
}) => {
  return (
    <>
      {gateway.state.v.state === GatewayState.HEALTHY && (
        <Tooltip title="Gateway is operating without any issues">
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{fontSize: iconSize}}
          />
        </Tooltip>
      )}
      {gateway.state.v.state === GatewayState.INITIALIZE && (
        <Tooltip title="Gateway is being initialized">
          <Spin />
        </Tooltip>
      )}
      {gateway.state.v.state === GatewayState.UNHEALTHY && (
        <Tooltip title={gateway.state.v.recommendedAction}>
          <WarningTwoTone twoToneColor="#ff4d4f" style={{fontSize: iconSize}} />
        </Tooltip>
      )}
      {gateway.state.v.state === GatewayState.OFFLINE && (
        <Tooltip title={gateway.state.v.recommendedAction}>
          <CloseCircleTwoTone
            twoToneColor="#ff4d4f"
            style={{fontSize: iconSize}}
          />
        </Tooltip>
      )}
    </>
  );
};

export default GatewayStatus;
