import {
  DeviceEvent,
  NodeState,
  NetworkNodeTemplate,
  NetworkRootTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import axios from 'axios';
import dayjs from 'dayjs';
import {AccumineCloudAsset, State} from '../interfaces';

import utc from 'dayjs/plugin/utc';
import {
  HEARTBEAT_INTERVAL,
  MAX_FPS,
} from '@accumine/xbee-network-manager/const';
import {wrap} from '@accumine/xbee-network-manager/util';
dayjs.extend(utc);

export const getAssets = async (): Promise<AccumineCloudAsset[]> => {
  try {
    let assets: AccumineCloudAsset[] = [],
      targets: State[] = [],
      response;
    response = await axios.get(`${process.env.REACT_APP_API}/v1/devices`);

    assets = response.data;

    response = await axios.post(
      `${process.env.REACT_APP_API}/v2/historical/raw`,
      {
        query: {
          deviceId: {$in: assets.map((a) => a.deviceId)},
          name: 'Hourly Target',
          timeEnd: null,
        },
      }
    );
    targets = response.data;

    for (let asset of assets) {
      const fixedTarget = targets.find(
        (t) => t.name === 'Hourly Target' && t.deviceId === asset.deviceId
      );
      if (fixedTarget) {
        asset.fixedHourlyTarget = fixedTarget.value;
      }
    }

    return assets;
  } catch (error) {
    throw error;
  }
};

export const removeAsset = async (asset: AccumineCloudAsset) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API}/v1/devices/${asset._id}`);
  } catch (error) {
    throw error;
  }
};

export const getNetwork = async (): Promise<NetworkTemplate> => {
  try {
    const {data} = await axios.post(
      `${process.env.REACT_APP_API}/v2/data-models/${localStorage['networkDataModelId']}/paginate`,
      {
        filter: [],
        sort: {},
        limit: 1,
      }
    );
    if (data.result.results.length > 0) {
      return data.result.results[0] as NetworkTemplate;
    } else {
      await axios.post(
        `${process.env.REACT_APP_API}/v2/data-models/${localStorage['networkDataModelId']}/add-record`,
        {
          roots: [],
          nodes: [],
          subscriptions: [],
        } as NetworkTemplate
      );
      return await getNetwork();
    }
  } catch (error) {
    throw error;
  }
};

export const addGateway = async (gateway: NetworkRootTemplate) => {
  try {
    let network = await getNetwork();
    if (
      network.roots.find((g) => g.registeredId.v === gateway.registeredId.v)
    ) {
      throw Error('A gateway with this MAC Address already exists.');
    }

    network.roots.push(gateway);
    await updateNetwork(network);
  } catch (error) {
    throw error;
  }
};

export const updateGateway = async (gateway: NetworkRootTemplate) => {
  try {
    let network = await getNetwork(),
      idx = network.roots.findIndex(
        (g) => g.registeredId.v === gateway.registeredId.v
      );
    if (idx === -1) {
      throw Error("This gateway doesn't exist");
    }

    network.roots[idx] = gateway;
    await updateNetwork(network);
  } catch (error) {
    throw error;
  }
};

export const removeGateway = async (gateway: NetworkRootTemplate) => {
  try {
    let network = await getNetwork();
    network.roots = network.roots.filter(
      (g) => g.registeredId.v !== gateway.registeredId.v
    );
    for (let node of network.nodes) {
      if (node.rttg.v[gateway.registeredId.v]) {
        let rttg = node.rttg.v;
        delete rttg[gateway.registeredId.v];
        node.rttg = wrap(rttg, 'cloud');
      }
    }
    await updateNetwork(network);
  } catch (error) {
    throw error;
  }
};

export const addSensorBot = async (node: NetworkNodeTemplate) => {
  try {
    let network = await getNetwork();
    if (network.nodes.find((n) => n.nodeId.v === node.nodeId.v)) {
      throw Error('A SensorBot with this MAC Address already exists.');
    }

    network.nodes.push(node);
    await updateNetwork(network);
  } catch (error) {
    throw error;
  }
};

export const updateSensorBot = async (sensorBot: NetworkNodeTemplate) => {
  try {
    let network = await getNetwork(),
      idx = network.nodes.findIndex((s) => s.nodeId.v === sensorBot.nodeId.v);
    if (idx === -1) {
      throw Error("This SensorBot doesn't exist");
    }

    if (sensorBot.state.v.state === NodeState.UPDATE) {
      if (
        !sensorBot.vref.v.synchronized ||
        !Object.values(sensorBot.pins).every((p) => p.v.synchronized)
      ) {
        sensorBot.backgroundState = wrap(NodeState.ONLINE, 'cloud');
      }
    } else if (
      sensorBot.state.v.state === NodeState.ONLINE ||
      sensorBot.state.v.state === NodeState.SYNCHRONIZE ||
      sensorBot.state.v.state === NodeState.STREAM
    ) {
      if (
        !sensorBot.vref.v.synchronized ||
        !Object.values(sensorBot.pins).every((p) => p.v.synchronized)
      ) {
        let state = sensorBot.state.v;
        state.state = NodeState.ONLINE;
        state.description = 'This Sensorbot is healthy but not synchronized.';
        state.recommendedAction = 'No action required.';
        sensorBot.state = wrap(state, 'cloud');
      }
    }
    network.nodes[idx] = sensorBot;

    for (let root of network.roots) {
      root.fps = wrap(
        network.nodes
          .filter((node) => node.gatewayId.v === root.registeredId.v)
          .reduce(
            (fps, node) => fps + node.fps.v + 1000 / HEARTBEAT_INTERVAL,
            0
          ),
        'cloud'
      );
      root.load = wrap((root.fps.v / MAX_FPS) * 100, 'cloud');
    }
    await updateNetwork(network);
  } catch (error) {
    throw error;
  }
};

export const removeSensorBot = async (sensorBot: NetworkNodeTemplate) => {
  try {
    let network = await getNetwork();
    network.nodes = network.nodes.filter(
      (n) => n.nodeId.v !== sensorBot.nodeId.v
    );
    await updateNetwork(network);
  } catch (error) {
    throw error;
  }
};

export const getLastEvent = async (device: string, type: string) => {
  const {data} = await axios.post(
    `${process.env.REACT_APP_API}/v2/data-models/${localStorage['deviceHistoryDataModelId']}/paginate`,
    {
      filter: [
        {
          type: 'Text',
          logic: 'is',
          value: [type],
          path: 'type',
        },
        {
          type: 'Text',
          logic: 'is',
          value: [device],
          path: 'deviceId',
        },
      ],
      sort: {
        fieldName: 'timestamp',
        direction: 'descending',
      },
      limit: 1,
    }
  );
  if (data.result.results.length > 0) {
    return data.result.results[0] as DeviceEvent;
  } else {
    return undefined;
  }
};

export const addEvent = async (item: DeviceEvent) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API}/v2/data-models/${localStorage['deviceHistoryDataModelId']}/add-record`,
      item
    );
  } catch (error) {
    throw error;
  }
};

export const getEvents = async (
  device: string,
  before: number,
  limit: number
) => {
  const {data} = await axios.post(
    `${process.env.REACT_APP_API}/v2/data-models/${localStorage['deviceHistoryDataModelId']}/paginate`,
    {
      filter: [
        {
          type: 'Text',
          logic: 'is',
          value: [device],
          path: 'deviceId',
        },
        {
          type: 'Number',
          logic: 'lessThan',
          value: before,
          path: 'timestamp',
        },
      ],
      sort: {
        fieldName: 'timestamp',
        direction: 'descending',
      },
      limit: limit,
    }
  );
  return data.result.results as DeviceEvent[];
};

export const updateNetwork = async (network: NetworkTemplate) => {
  try {
    if (network._id) {
      await axios.patch(
        `${process.env.REACT_APP_API}/v2/data-models/${localStorage['networkDataModelId']}/edit-record`,
        network
      );
    } else {
      await axios.post(
        `${process.env.REACT_APP_API}/v2/data-models/${localStorage['networkDataModelId']}/add-record`,
        network
      );
    }
  } catch (error) {
    throw error;
  }
};
