import {
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {CloseOutlined} from '@ant-design/icons';
import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Row,
  Table,
  Typography,
} from 'antd';
import React, {useContext, useEffect, useRef} from 'react';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {updateNetwork} from '../../util/networkManager';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow = ({index, ...props}: {index: string}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleDelete,
  ...restProps
}: {
  title: string;
  editable: boolean;
  children: any;
  dataIndex: string;
  record: any;
  handleSave: Function;
  handleDelete: Function;
}) => {
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && inputRef.current) {
      //@ts-ignore
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (form) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const save = async () => {
    try {
      if (form) {
        const values = await form.validateFields();
        await handleSave({...record, ...values});
        toggleEdit();
      }
    } catch (error) {
      console.log('Save failed:', error);
    }
  };

  let childNode = editing ? (
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
      rules={[
        {
          required: true,
          type: 'email',
          message: 'Please enter a valid email.',
        },
      ]}
    >
      <Input ref={inputRef} onPressEnter={save} onBlur={save} />
    </Form.Item>
  ) : (
    <Row>
      <Typography.Text
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children[1] ? (
          children
        ) : (
          <div style={{cursor: 'pointer'}}>Add an email address...</div>
        )}
      </Typography.Text>
      {children[1] ? (
        <Button
          style={{marginLeft: 'auto'}}
          type="text"
          size="small"
          shape="circle"
          loading={loading}
          icon={
            <CloseOutlined
              twoToneColor="#ff4d4f"
              style={{fontSize: '15px'}}
              onClick={async () => {
                setLoading(true);
                await handleDelete(children[1]);
                setLoading(false);
              }}
            />
          }
        />
      ) : (
        <div />
      )}
    </Row>
  );

  return <td {...restProps}>{childNode}</td>;
};

const ShowSubscriptions = ({network}: {network: NetworkTemplate}) => {
  const navigate = useNavigate();
  let newSubs = network.subscriptions.map((email, index) => {
    return {key: index, email: email};
  });
  newSubs.push({key: newSubs.length, email: ''});
  const [subscriptions, setSubscriptions] = useState(newSubs);

  return (
    <Modal
      visible={true}
      title={
        <Row>
          <Typography.Title level={4}>Subscriptions</Typography.Title>
          <Typography.Title level={5} type="secondary">
            {
              'Receive notifications when Gateways in this network go online & offline and receive daily status updates'
            }
          </Typography.Title>
        </Row>
      }
      cancelButtonProps={{style: {display: 'none'}}}
      footer={null}
      onCancel={() => navigate(-1)}
      onOk={() => navigate(-1)}
    >
      <Table
        size="small"
        components={{
          body: {
            row: EditableRow,
            cell: EditableCell,
          },
        }}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={subscriptions}
        columns={[
          {
            title: 'Email',
            dataIndex: 'email',
            width: '100%',
            onCell: (record: {key: number; email: string}) => ({
              record,
              editable: true,
              dataIndex: 'email',
              title: 'Email',
              handleSave: async (row: {key: number; email: string}) => {
                try {
                  if (row.email) {
                    let subscriptions = network.subscriptions;
                    subscriptions[row.key] = row.email;
                    network.subscriptions = subscriptions;
                    await updateNetwork(network);
                    let newSubs = network.subscriptions.map((email, index) => {
                      return {key: index, email: email};
                    });
                    newSubs.push({key: newSubs.length, email: ''});
                    setSubscriptions(newSubs);
                    message.success("Saved email information.");
                  }
                } catch (error) {
                  message.error(`Could not save email: ${error}`);
                }
              },
              handleDelete: async (email: string) => {
                try {
                  let subscriptions = network.subscriptions;
                  let ind = subscriptions.findIndex(
                    (sub) => sub === email
                  );
                  if (ind !== -1) {
                    subscriptions.splice(ind, 1);
                    network.subscriptions = subscriptions;
                    await updateNetwork(network);
                    let newSubs = network.subscriptions.map((email, index) => {
                      return {key: index, email: email};
                    });
                    newSubs.push({key: newSubs.length, email: ''});
                    setSubscriptions(newSubs);
                    message.success("Removed email.");
                  }
                } catch (error) {
                  message.error(`Could not remove email: ${error}`);
                }
              },
            }),
          },
        ]}
      />
    </Modal>
  );
};

export default ShowSubscriptions;
