import {
    GatewayState,
    NetworkTemplate,
    NodeState,
} from '@accumine/xbee-network-manager/types';
import { wrap } from '@accumine/xbee-network-manager/util';
import { Image, Form, Input, Modal, Typography, message, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { addGateway, addSensorBot } from '../../util/networkManager';
import { Dirent } from 'fs';
import MacAddressImage from '../../images/gateway-mac.png';

const AddSensorBotModal = ({ network }: { network: NetworkTemplate }) => {
    const { Item } = Form;
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const fieldRequired = {
        required: true,
        message: 'Full address is required.',
    };

    const inputStyle = { width: '12%' };

    const submit = async () => {
        setLoading(true);
        let formData: {
            macAddress1: string;
            macAddress2: string;
            macAddress3: string;
            macAddress4: string;
            macAddress5: string;
            macAddress6: string;
            macAddress7: string;
            macAddress8: string;
        } = {
            macAddress1: '',
            macAddress2: '',
            macAddress3: '',
            macAddress4: '',
            macAddress5: '',
            macAddress6: '',
            macAddress7: '',
            macAddress8: '',
        };
        try {
            formData = await form.validateFields();
            let nodeId = (
                formData.macAddress1 +
                formData.macAddress2 +
                formData.macAddress3 +
                formData.macAddress4 +
                formData.macAddress5 +
                formData.macAddress6 +
                formData.macAddress7 +
                formData.macAddress8
            ).toLowerCase();
            await addSensorBot({
                registrationTimestamp: dayjs().utc().valueOf(),
                nodeId: wrap(nodeId, 'cloud'),
                deviceId: wrap('', 'cloud'),
                gatewayId: wrap('', 'cloud'),
                hardwareVersion: wrap('', 'cloud'),
                pins: {},
                vref: wrap(
                    {
                        synchronized: false,
                        vref: -1,
                    },
                    'cloud'
                ),
                rtt: wrap(-1, 'cloud'),
                rtts: wrap([], 'cloud'),
                rttg: wrap({}, 'cloud'),
                attemptToOpen: wrap(true, 'cloud'),
                attemptToSync: wrap(true, 'cloud'),
                attemptToReset: wrap(false, 'cloud'),
                initialized: wrap(false, 'cloud'),
                hide: wrap(false, 'cloud'),
                locked: wrap(false, 'cloud'),
                backgroundState: wrap(undefined, 'cloud'),
                state: wrap(
                    {
                        state: NodeState.INITIALIZE,
                        description: 'Sensorbot is initializing.',
                        progress: 0,
                        recommendedAction: 'No action required.',
                        errors: [],
                        numConsecutiveErrors: 0,
                        numErrorSinceRestart: 0,
                        onlineOn: -1,
                    },
                    'cloud'
                ),
                version: wrap('', 'cloud'),
                networkId: wrap('0x7FFF', 'cloud'),
                databaseId: wrap(
                    localStorage.getItem('databaseId') as string,
                    'cloud'
                ),
                edges: [],
                fps: wrap(0, 'cloud'),
            });
            navigate(`/sensorbots`);
        } catch (error) {
            message.error(`Error: ${error}`);
        }
        setLoading(false);
    };
    return (
        <Modal
            visible={true}
            title='Add SensorBot'
            width={'60%'}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText='Submit'
            onOk={submit}
            okButtonProps={{ loading }}
            onCancel={() => {
                navigate(-1);
            }}>
            <Form form={form} name='login' layout='vertical'>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>
                    <Typography.Text>
                        Locate the MAC Address on the SensorBot:
                    </Typography.Text>
                    <Image
                        preview={false}
                        src={MacAddressImage}
                        height='200px'
                        style={{ margin: 'auto' }}
                    />
                </div>

                <Item label='MAC Address:'>
                    <Input.Group compact>
                        <Item
                            label='MAC'
                            className='col-4'
                            name='macAddress1'
                            initialValue={'00'}
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}
                            noStyle>
                            <Input maxLength={2} style={{ width: '6%' }} />
                        </Item>
                        <Item
                            name='macAddress2'
                            initialValue={'13'}
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}
                            noStyle>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                        <Item
                            noStyle
                            name='macAddress3'
                            initialValue={'A2'}
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                        <Item
                            noStyle
                            name='macAddress4'
                            initialValue={'00'}
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                        <Item
                            required
                            noStyle
                            name='macAddress5'
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                        <Item
                            required
                            noStyle
                            name='macAddress6'
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                        <Item
                            required
                            noStyle
                            name='macAddress7'
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                        <Item
                            required
                            noStyle
                            name='macAddress8'
                            rules={[
                                fieldRequired,
                                { validator: validateMacAddress },
                            ]}>
                            <Input
                                addonBefore=':'
                                maxLength={2}
                                style={inputStyle}
                            />
                        </Item>
                    </Input.Group>
                </Item>
            </Form>

            <Typography.Text>
                *Don't forget to assign the SensorBot a Gateway
            </Typography.Text>
        </Modal>
    );
};

export function validateMacAddress(_: any, value: string) {
    const regex = /^([0-9a-fA-F]{2})$/;
    if (value && !regex.test(value)) {
        return Promise.reject(new Error('Please enter a valid MAC address.'));
    }
    return Promise.resolve();
}

export default AddSensorBotModal;
