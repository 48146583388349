import {
  DeleteTwoTone,
  EditTwoTone,
  EyeInvisibleTwoTone,
  EyeTwoTone,
  LockTwoTone,
  UndoOutlined,
  UnlockTwoTone,
} from '@ant-design/icons';
import {
  Table,
  Typography,
  Button,
  Tooltip,
  Space,
  message,
  Popconfirm,
  Col,
  Statistic,
  Row,
  Card,
} from 'antd';
import {
  State,
  NodeState,
  NetworkNodeTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {AccumineCloudAsset} from '../../interfaces';
import SensorBotSignal from '../../components/SensorBotSignal';
import SensorBotStatus from '../../components/SensorBotStatus';
import AssetSelector from './assetSelector';
import GatewaySelector from './gatewaySelector';
import SensorBotUptime from '../../components/SensorBotUptime';
import {URLSearchParamsInit, useNavigate} from 'react-router-dom';
import {
  addEvent,
  removeSensorBot,
  updateSensorBot,
} from '../../util/networkManager';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import { wrap } from '@accumine/xbee-network-manager/util';

const SensorBotTable = ({
  network,
  searchParams,
  setSearchParams,
  assets,
}: {
  network: NetworkTemplate;
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void;
  assets: AccumineCloudAsset[];
}) => {
  const navigate = useNavigate();
  const [canModify, setCanModify] = useState(
    network.nodes.reduce((o, n) => {
      o[n.nodeId.v] = n.state.v.state !== NodeState.UPDATE;
      return o;
    }, {} as {[key: string]: boolean})
  );
  const [loadingLocked, setLoadingLocked] = useState(
    network.nodes.reduce((o, n) => {
      o[n.nodeId.v] = false;
      return o;
    }, {} as {[key: string]: boolean})
  );
  const [loadingReset, setLoadingReset] = useState(
    network.nodes.reduce((o, n) => {
      o[n.nodeId.v] = false;
      return o;
    }, {} as {[key: string]: boolean})
  );
  const [loadingUpdate, setLoadingUpdate] = useState(
    network.nodes.reduce((o, n) => {
      o[n.nodeId.v] = false;
      return o;
    }, {} as {[key: string]: boolean})
  );
  const [loadingDelete, setLoadingDelete] = useState(
    network.nodes.reduce((o, n) => {
      o[n.nodeId.v] = false;
      return o;
    }, {} as {[key: string]: boolean})
  );
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [selected, setSelected] = useState(0);
  const [tableData, setTableData] = useState(
    network.nodes.filter((n) => !n.hide.v).map(n => {
      return {
        ...{key: n.nodeId.v},
        ...n
      }
    })
  );

  useEffect(() => {
    switch (selected) {
      case 0:
        setTableData(network.nodes.filter((n) => !n.hide.v).map(n => {
          return {
            ...{key: n.nodeId.v},
            ...n
          }
        }));
        break;
      case 1:
        setTableData(
          network.nodes
            .filter((n) => !n.hide.v)
            .filter((n) => n.state.v.state === NodeState.INITIALIZE)
            .map(n => {
              return {
                ...{key: n.nodeId.v},
                ...n
              }
            })
        );
        break;
      case 2:
        setTableData(
          network.nodes
            .filter((n) => !n.hide.v)
            .filter(
              (n) =>
                n.state.v.state === NodeState.STREAM ||
                n.state.v.state === NodeState.SYNCHRONIZE ||
                n.state.v.state === NodeState.ONLINE ||
                n.state.v.state === NodeState.UPDATE
            ).map(n => {
              return {
                ...{key: n.nodeId.v},
                ...n
              }
            })
        );
        break;
      case 3:
        setTableData(
          network.nodes
            .filter((n) => !n.hide.v)
            .filter(
              (n) =>
                n.state.v.state === NodeState.UNHEALTHY ||
                n.state.v.state === NodeState.UNREACHABLE ||
                n.state.v.state === NodeState.UNSYNCABLE
            ).map(n => {
              return {
                ...{key: n.nodeId.v},
                ...n
              }
            })
        );
        break;
      case 4:
        setTableData(
          network.nodes
            .filter((n) => !n.hide.v)
            .filter((n) => n.state.v.state === NodeState.OFFLINE)
            .map(n => {
              return {
                ...{key: n.nodeId.v},
                ...n
              }
            })
        );
        break;
      case 5:
        setTableData(network.nodes.filter((n) => n.hide.v).map(n => {
          return {
            ...{key: n.nodeId.v},
            ...n
          }
        }));
    }
  }, [network, selected]);

  useEffect(() => {
    setCanModify(
      network.nodes.reduce((o, n) => {
        o[n.nodeId.v] = n.state.v.state !== NodeState.UPDATE;
        return o;
      }, {} as {[key: string]: boolean})
    );
  }, [network]);

  return (
    <>
      <Col
        span={24}
        style={{
          padding: '10px 16px',
          backgroundColor: '#fafafa',
          overflow: 'auto',
          marginTop: -20,
          marginBottom: 0,
        }}
      >
        <Row justify="center" gutter={10}>
          <Col span={4}>
            <Card
              hoverable
              size="small"
              style={{width: '100%'}}
              onClick={() => {
                setSelected(0);
              }}
            >
              <Statistic
                title="Total"
                value={network.nodes.filter((n) => !n.hide.v).length}
                valueStyle={{color: selected === 0 ? '#1890ff' : undefined}}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              size="small"
              style={{width: '100%'}}
              onClick={() => {
                setSelected(1);
              }}
            >
              <Statistic
                title="Initializing"
                value={
                  network.nodes
                    .filter((n) => !n.hide.v)
                    .filter((n) => n.state.v.state === NodeState.INITIALIZE)
                    .length
                }
                valueStyle={{color: selected === 1 ? '#1890ff' : undefined}}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              size="small"
              style={{width: '100%'}}
              onClick={() => {
                setSelected(2);
              }}
            >
              <Statistic
                title="Healthy"
                value={
                  network.nodes
                    .filter((n) => !n.hide.v)
                    .filter(
                      (n) =>
                        n.state.v.state === NodeState.STREAM ||
                        n.state.v.state === NodeState.SYNCHRONIZE ||
                        n.state.v.state === NodeState.ONLINE ||
                        n.state.v.state === NodeState.UPDATE
                    ).length
                }
                valueStyle={{color: selected === 2 ? '#1890ff' : undefined}}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              size="small"
              style={{width: '100%'}}
              onClick={() => {
                setSelected(3);
              }}
            >
              <Statistic
                title="Unhealthy"
                value={
                  network.nodes
                    .filter((n) => !n.hide.v)
                    .filter(
                      (n) =>
                        n.state.v.state === NodeState.UNHEALTHY ||
                        n.state.v.state === NodeState.UNREACHABLE ||
                        n.state.v.state === NodeState.UNSYNCABLE
                    ).length
                }
                valueStyle={{color: selected === 3 ? '#1890ff' : undefined}}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              size="small"
              style={{width: '100%'}}
              onClick={() => {
                setSelected(4);
              }}
            >
              <Statistic
                title="Offline"
                value={
                  network.nodes
                    .filter((n) => !n.hide.v)
                    .filter((n) => n.state.v.state === NodeState.OFFLINE).length
                }
                valueStyle={{color: selected === 4 ? '#1890ff' : undefined}}
              />
            </Card>
          </Col>
          <Col span={4}>
            <Card
              hoverable
              size="small"
              style={{width: '100%'}}
              onClick={() => {
                setSelected(5);
              }}
            >
              <Statistic
                title="Hidden"
                value={network.nodes.filter((n) => n.hide.v).length}
                valueStyle={{color: selected === 5 ? '#1890ff' : undefined}}
              />
            </Card>
          </Col>
        </Row>
      </Col>
      <Table
        bordered={true}
        pagination={{
          position: ['bottomCenter'],
          pageSize: 8,
          current: parseInt(searchParams.get('page') as string),
          onChange: (page) => {
            let params: {
              search?: string;
              page: string;
            } = {
              page: page.toString(),
            };
            if (searchParams.get('search')) {
              params.search = searchParams.get('search') as string;
            }
            setSearchParams(params);
          },
        }}
        size="small"
        dataSource={tableData.filter((n) => {
          try {
            const nodeId = 
              !!n.nodeId.v &&
              !!n.nodeId.v
                .toLowerCase()
                .includes(searchParams.get('search')?.toLowerCase() || ''),
              gatewayId =
                !!n.gatewayId.v &&
                !!n.gatewayId.v
                  .toLowerCase()
                  .includes(searchParams.get('search')?.toLowerCase() || ''),
              deviceId =
                !!n.deviceId.v &&
                !!n.deviceId.v
                  .toLowerCase()
                  .includes(searchParams.get('search')?.toLowerCase() || '');
            let gatewayDisplayName = false,
              deviceName = false;

            if (n.gatewayId.v) {
              const gateway = network.roots.find(
                (r) => r.registeredId.v === n.gatewayId.v
              );
              if (
                gateway &&
                gateway.displayName.v
                  .toLowerCase()
                  .includes(searchParams.get('search')?.toLowerCase() || '')
              ) {
                gatewayDisplayName = true;
              }
            }
            if (n.deviceId.v) {
              const asset = assets.find((a) => a.deviceId === n.deviceId.v);
              if (
                asset &&
                asset.name
                  .toLowerCase()
                  .includes(searchParams.get('search')?.toLowerCase() || '')
              ) {
                deviceName = true;
              }
            }
            return (
              nodeId ||
              gatewayId ||
              gatewayDisplayName ||
              deviceId ||
              deviceName
            );
          } catch (error) {
            console.log(`Error filtering nodes: ${error}`);
            return true;
          }
        })}
        columns={[
          {
            title: 'Lock',
            align: 'center',
            dataIndex: 'locked',
            key: 'locked',
            sorter: (a, b) => {
              if (a.locked.v && !b.locked.v) {
                return -1;
              }
              if (!a.locked.v && b.locked.v) {
                return 1;
              }
              return 0;
            },
            render: (_locked: string, record: NetworkNodeTemplate) => (
              <Button
                size="middle"
                type="ghost"
                icon={
                  record.locked.v ? (
                    <UnlockTwoTone style={{fontSize: 20}} />
                  ) : (
                    <LockTwoTone style={{fontSize: 20}} />
                  )
                }
                onClick={async () => {
                  loadingLocked[record.nodeId.v] = true;
                  setLoadingLocked(loadingReset);
                  try {
                    record.locked = wrap(!record.locked.v, 'cloud');
                    await updateSensorBot(record);
                    await addEvent({
                      userId: localStorage.getItem('username') as string,
                      deviceId: record.nodeId.v,
                      type: 'event',
                      description: `User ${
                        record.locked.v ? 'locked' : 'unlocked'
                      } SensorBot.`,
                      timestamp: dayjs().utc().valueOf(),
                    });
                    message.success(`${
                      record.locked.v ? 'Locked' : 'Unlocked'
                    } SensorBot configuration`);
                  } catch (error) {
                    message.error(`Could not lock SensorBot: ${error}`);
                  }
                  loadingLocked[record.nodeId.v] = false;
                  setLoadingLocked(loadingReset);
                }}
              />
            ),
          },
          {
            title: 'Asset',
            align: 'center',
            dataIndex: 'deviceId',
            key: 'deviceId',
            sorter: (a, b) => {
              if (a.deviceId.v < b.deviceId.v) {
                return -1;
              }
              if (a.deviceId.v > b.deviceId.v) {
                return 1;
              }
              return 0;
            },
            render: (_deviceId: string, record: NetworkNodeTemplate) => (
              <AssetSelector network={network} sensorBot={record} assets={assets} />
            ),
          },
          {
            title: 'Gateway',
            align: 'center',
            dataIndex: 'gatewayId',
            key: 'gatewayId',
            filters: Object.values(network.roots).map((root) => {
              let filter = root.displayName.v
                ? root.displayName.v
                : root.registeredId.v.toUpperCase();
              return {
                text: filter,
                value: root.registeredId.v,
              };
            }),
            onFilter: (value, record) => record.gatewayId.v === value,
            sorter: (a, b) => {
              if (a.gatewayId.v < b.gatewayId.v) {
                return -1;
              }
              if (a.gatewayId.v > b.gatewayId.v) {
                return 1;
              }
              return 0;
            },
            render: (_gatewayId: string, record: NetworkNodeTemplate) => (
              <GatewaySelector network={network} sensorBot={record} />
            ),
          },
          {
            title: 'Serial No.',
            align: 'center',
            dataIndex: 'nodeId',
            key: 'nodeId',
            sorter: (a, b) => {
              if (a.nodeId.v < b.nodeId.v) {
                return -1;
              }
              if (a.nodeId.v > b.nodeId.v) {
                return 1;
              }
              return 0;
            },
            render: (_nodeId: string, record: NetworkNodeTemplate) => (
              <Typography.Text copyable={true}>
                {record.nodeId.v.toUpperCase()}
              </Typography.Text>
            ),
          },
          {
            title: 'Version',
            align: 'center',
            dataIndex: 'version',
            key: 'version',
            sorter: (a, b) => {
              if (a.version.v < b.version.v) {
                return -1;
              }
              if (a.version.v > b.version.v) {
                return 1;
              }
              return 0;
            },
            render: (_version: string, record: NetworkNodeTemplate) => (
              <Typography.Text>{record.version.v ? record.version.v : 'N/A'}</Typography.Text>
            ),
          },
          {
            title: 'Uptime',
            align: 'center',
            dataIndex: 'state',
            key: 'state',
            render: (_state: string, record: NetworkNodeTemplate) => (
              <SensorBotUptime sensorBot={record} />
            ),
          },
          {
            title: 'Communication',
            align: 'center',
            dataIndex: 'rtt',
            key: 'rtt',
            sorter: (a, b) => a.rtt.v - b.rtt.v,
            render: (_rtt: number, record: NetworkNodeTemplate) => (
              <SensorBotSignal sensorBot={record} />
            ),
          },
          {
            title: 'Status',
            align: 'center',
            dataIndex: 'state',
            key: 'state',
            sorter: (a, b) => {
              if (a.state.v.state < b.state.v.state) {
                return -1;
              }
              if (a.state.v.state > b.state.v.state) {
                return 1;
              }
              return 0;
            },
            render: (_state: State, record: NetworkNodeTemplate) => (
              <SensorBotStatus sensorBot={record} iconSize={20} />
            ),
          },
          {
            title: 'Actions',
            align: 'center',
            width: 150,
            key: 'inputs',
            render: (record: NetworkNodeTemplate) => {
              return (
                <Space>
                  <Tooltip title="Configure device.">
                    <Button
                      disabled={
                        !record.initialized.v || record.hide.v
                      }
                      size="middle"
                      type="ghost"
                      icon={<EditTwoTone style={{fontSize: 20}} />}
                      onClick={() => navigate(`${record.nodeId.v}/configuration`)}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      canModify[record.nodeId.v]
                        ? record.hardwareVersion.v === '1' ||
                          record.hardwareVersion.v === '3'
                          ? 'Hardware does not support remote resets.'
                          : 'Reset device.'
                        : 'Wait to finish updating.'
                    }
                  >
                    <Button
                      size="middle"
                      type="ghost"
                      disabled={
                        record.hardwareVersion.v === '1' ||
                        record.hardwareVersion.v === '3' ||
                        !canModify[record.nodeId.v] ||
                        record.hide.v
                      }
                      icon={<UndoOutlined style={{fontSize: 20}} />}
                      onClick={async () => {
                        loadingReset[record.nodeId.v] = true;
                        setLoadingReset(loadingReset);
                        try {
                          record.attemptToReset = wrap(true, 'cloud');
                          await updateSensorBot(record);
                          await addEvent({
                            userId: localStorage.getItem('username') as string,
                            deviceId: record.nodeId.v,
                            type: 'event',
                            description: `User reset SensorBot.`,
                            timestamp: dayjs().utc().valueOf(),
                          });
                          message.success(
                            'Resetting SensorBot. It may take a minute.'
                          );
                        } catch (error) {
                          message.error(`Could not reset SensorBot: ${error}`);
                        }
                        loadingReset[record.nodeId.v] = false;
                        setLoadingReset(loadingReset);
                      }}
                    />
                  </Tooltip>
                  <Tooltip
                    title={
                      record.hide.v
                        ? 'Unhide SensorBot.'
                        : canModify[record.nodeId.v]
                        ? 'Not yours? Hide SensorBot.'
                        : 'Wait to finish updating.'
                    }
                  >
                    <Button
                      type="default"
                      loading={loadingUpdate[record.nodeId.v]}
                      disabled={!record.initialized.v || record.locked.v || !canModify[record.nodeId.v]}
                      icon={
                        record.hide.v ? (
                          <EyeTwoTone
                            twoToneColor="#1890ff"
                            style={{fontSize: '20px'}}
                          />
                        ) : (
                          <EyeInvisibleTwoTone
                            twoToneColor="#1890ff"
                            style={{fontSize: '20px'}}
                          />
                        )
                      }
                      onClick={async () => {
                        loadingUpdate[record.nodeId.v] = true;
                        setLoadingUpdate(loadingUpdate);
                        try {
                          if (record.hide.v) {
                            record.hide = wrap(false, 'cloud');
                          } else {
                            record.hide = wrap(true, 'cloud');
                            record.gatewayId = wrap('', 'cloud');
                            record.deviceId = wrap('', 'cloud');
                          }
                          await updateSensorBot(record);
                          await addEvent({
                            userId: localStorage.getItem('username') as string,
                            deviceId: record.nodeId.v,
                            type: 'event',
                            description: `User ${
                              record.hide.v ? 'hide' : 'unhide'
                            } SensorBot.`,
                            timestamp: dayjs().utc().valueOf(),
                          });
                          message.success(`SensorBot ${record.hide.v ? 'hidden' : 'unhidden'}`);
                        } catch (error) {
                          message.error(`Could not hide SensorBot: ${error}`);
                        }
                        loadingUpdate[record.nodeId.v] = false;
                        setLoadingUpdate(loadingUpdate);
                      }}
                    ></Button>
                  </Tooltip>
                  <Popconfirm
                    title="Are you sure?"
                    onVisibleChange={(visible) => setPopConfirmVisible(visible)}
                    onConfirm={async () => {
                      loadingDelete[record.nodeId.v] = true;
                      setLoadingDelete(loadingDelete);
                      try {
                        await removeSensorBot(record);
                        message.success('SensorBot deleted');
                      } catch (error) {
                        message.error(`Could not delete SensorBot: ${error}`);
                      }
                      loadingDelete[record.nodeId.v] = false;
                      setLoadingDelete(loadingDelete);
                    }}
                    onCancel={undefined}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      visible={popConfirmVisible ? false : undefined}
                      title={
                        canModify[record.nodeId.v]
                          ? 'Delete SensorBot.'
                          : 'Wait to finish updating.'
                      }
                    >
                      <Button
                        type="default"
                        danger
                        loading={loadingDelete[record.nodeId.v]}
                        disabled={record.locked.v || !canModify[record.nodeId.v]}
                        icon={
                          <DeleteTwoTone
                            twoToneColor="#ff4d4f"
                            style={{fontSize: '20px'}}
                          />
                        }
                      ></Button>
                    </Tooltip>
                  </Popconfirm>
                </Space>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default SensorBotTable;
