import {
  DeviceEvent,
  NetworkNodeTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Modal,
  Result,
  Row,
  Timeline,
  Typography,
} from 'antd';
import {useEffect} from 'react';
import {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useNavigate, useParams} from 'react-router-dom';
import {getEvents} from '../../util/networkManager';
import MQTT from '../../util/mqtt';
import dayjs from 'dayjs';
import {
  CloseCircleTwoTone,
  EditTwoTone,
  ExclamationCircleTwoTone,
  UpOutlined,
} from '@ant-design/icons';
import { AccumineCloudAsset } from '../../interfaces';

const SensorBotHistory = ({
  network,
  assets,
  mqtt,
}: {
  network: NetworkTemplate;
  assets: AccumineCloudAsset[];
  mqtt: typeof MQTT;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const sensorBot = network.nodes.find((r) => r.nodeId.v === params.nodeId);

  return (
    <>
      {sensorBot ? (
        <SensorBotHistoryChild sensorBot={sensorBot} assets={assets} mqtt={mqtt} />
      ) : (
        <Col span="19">
          <Result
            status="404"
            title="Sensorbot doesn't exist"
            subTitle="The sensorbot you've selected does not exist."
            extra={
              <Button
                type="primary"
                onClick={() => navigate('/sensorbots?page=1')}
              >
                View all SensorBots
              </Button>
            }
          />
        </Col>
      )}
    </>
  );
};

const SensorBotHistoryChild = ({
  sensorBot,
  assets,
  mqtt,
}: {
  sensorBot: NetworkNodeTemplate;
  assets: AccumineCloudAsset[];
  mqtt: typeof MQTT;
}) => {
  const navigate = useNavigate();

  const [deviceHistory, setdeviceHistory] = useState([] as DeviceEvent[]);
  const [lastDate, setLastDate] = useState(dayjs().utc().valueOf());
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState(['event', 'error', 'note'] as string[]);
  
  const fetchEvents = async () => {
    if (sensorBot) {
      setLoading(true);
      let events = await getEvents(sensorBot.nodeId.v, lastDate, 10);
      if (events && events.length !== 0) {
        setdeviceHistory(deviceHistory.concat(events));
        setLastDate(events[events.length - 1].timestamp);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setLoading(false);
    }
  };

  const handleNewEvent = (item: DeviceEvent) => {
    if (item.deviceId === sensorBot.nodeId.v) {
      deviceHistory.unshift(item);
      setdeviceHistory(deviceHistory);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (sensorBot) {
      mqtt.router.on(
        `${localStorage['databaseId']}/${localStorage['deviceHistoryDataModelId']}/create`,
        handleNewEvent
      );
      return () => {
        mqtt.router.removeListener(
          `${localStorage['databaseId']}/${localStorage['deviceHistoryDataModelId']}/create`,
          handleNewEvent
        );
      };
    }
  }, [deviceHistory]);

  return (
    <Modal
      width={window.innerWidth * 0.4}
      visible={true}
      title={
        <Row>
          <Col span={24}>
            <Typography.Title style={{float: 'left'}} level={4}>
              Device History
            </Typography.Title>
            <Checkbox.Group
              style={{
                float: 'right',
                marginTop: 5,
                marginRight: 20,
              }}
              options={[
                {label: 'Events', value: 'event'},
                {label: 'Errors', value: 'error'},
                {label: 'Notes', value: 'note'},
              ]}
              defaultValue={['event', 'error', 'note']}
              onChange={(checked) => {
                setHasMore(true);
                setFilter(checked as string[]);
              }}
            />
          </Col>
        </Row>
      }
      cancelButtonProps={{style: {display: 'none'}}}
      footer={null}
      onCancel={() => navigate(-1)}
      onOk={() => navigate(-1)}
    >
      <InfiniteScroll
        style={{marginTop: 10}}
        height={window.innerHeight * 0.5}
        dataLength={
          deviceHistory.filter((item) => filter.some((v) => v === item.type))
            .length
        }
        next={fetchEvents}
        hasMore={hasMore}
        loader=""
        endMessage={<Divider plain>Beginning of Device History</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <Timeline
          pending={loading ? 'Loading more history...' : undefined}
          mode="right"
        >
          <Timeline.Item key="now" color="green" label={dayjs().format('LLLL')}>
            Now
          </Timeline.Item>
          {deviceHistory
            .filter((item) => filter.some((v) => v === item.type))
            .map((item) => {
              let dot, content;
              if (item.type === 'error') {
                dot = <CloseCircleTwoTone twoToneColor={'#ff4d4f'} />;
              } else if (item.type === 'note') {
                dot = <EditTwoTone twoToneColor={'#8c8c8c'} />;
              } else {
                dot = <ExclamationCircleTwoTone twoToneColor={'#40a9ff'} />;
              }

              let name = '';
              if (sensorBot.deviceId.v) {
                let asset = assets.find(a => a.deviceId === sensorBot.deviceId.v);
                if (asset && asset.name) {
                  name = `${asset.name} (${sensorBot.nodeId.v})`;
                }
              }
              if (item.userId) {
                content = (
                  <>
                    <p>{name ? item.description.replace(sensorBot.nodeId.v, name) : item.description}</p>
                    <p>{`User: ${item.userId}`}</p>
                  </>
                );
              } else {
                content = <p>{name ? item.description.replace(sensorBot.nodeId.v, name) : item.description}</p>;
              }
              return (
                <Timeline.Item
                  key={dayjs(item.timestamp).valueOf().toString()}
                  dot={dot}
                  label={dayjs(item.timestamp).format('LLLL')}
                >
                  {content}
                </Timeline.Item>
              );
            })}
          <Timeline.Item key='end' dot={<UpOutlined/>}/>
        </Timeline>
      </InfiniteScroll>
    </Modal>
  );
};

export default SensorBotHistory;
