import { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Link, Navigate } from 'react-router-dom';
import { Badge, Layout, Menu, message, Spin, Tooltip } from 'antd';
import './style.css';
import 'antd/dist/antd.css';
import LogoImage from './images/L2L_ConnectLogo_greenblack.png';
import jwt from 'jwt-decode';
import {
    GatewayState,
    NodeState,
    NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import { AccumineCloudAsset, IDecodedToken, IDataModel } from './interfaces';
import Login from './views/login';
import MQTT from './util/mqtt';
import { LogoutOutlined } from '@ant-design/icons';
import Gateways from './views/gateways';
import SensorBots from './views/sensorbots';
import axios, { AxiosError } from 'axios';
import GatewayDetails from './views/gateways/gatewayDetails';
import { getAssets, getNetwork } from './util/networkManager';
import Assets from './views/assets';
import AddGatewayModal from './views/gateways/addGatewayModal';
import ShowSubscriptions from './views/gateways/subscriptionsModal';
import GatewayDiagnostics from './views/gateways/gatewayDiagnostics';
import AssetDetails from './views/assets/assetDetails';
import AssetModal from './views/assets/assetModal';
import SensorBotDetails from './views/sensorbots/sensorBotDetails';
import SensorBotDiagnostics from './views/sensorbots/sensorBotDiagnostics';
import GatewayHistory from './views/gateways/gatewayHistory';
import SensorBotHistory from './views/sensorbots/sensorbotHistory';
import AddNewNote from './views/sensorbots/addNewNote';
import AddSensorBotModal from './views/sensorbots/addSensorBotModal';

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error: AxiosError) => {
        if (error && error.response && error.response.status === 401) {
            localStorage.clear();
            window.location.reload();
        } else {
            throw error;
        }
    }
);

const App = ({ mqtt }: { mqtt: typeof MQTT }) => {
    const { pathname } = useLocation();
    const query = new URLSearchParams(useLocation().search);
    const [factoryId, setFactoryId] = useState('');
    const [network, _setNetwork] = useState<NetworkTemplate>({
        roots: [],
        nodes: [],
        subscriptions: [],
    });
    const [assets, _setAssets] = useState<AccumineCloudAsset[]>([]);
    const [token, setToken] = useState<string | null>(() => {
        let token = null;
        const queryToken = query.get('token');
        if (queryToken) {
            token = queryToken;
            localStorage['token'] = queryToken;
        } else if (localStorage['token']) {
            token = localStorage['token'];
        }
        return token;
    });
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);

    const setNetwork = async (newNetwork?: NetworkTemplate) => {
        setLoading(true);
        try {
            _setNetwork(newNetwork || (await getNetwork()));
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const setAssets = async () => {
        setLoading(true);
        try {
            _setAssets(await getAssets());
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const init = async () => {
        try {
            if (token) {
                axios.defaults.headers.common.Authorization =
                    localStorage['token'];

                const { factoryId, username }: IDecodedToken = jwt(token);
                setFactoryId(factoryId);
                localStorage['databaseId'] = factoryId;
                localStorage['username'] = username;

                const dataModels = (
                    await axios.get(
                        `${process.env.REACT_APP_API}/v2/data-models`
                    )
                ).data as IDataModel[];
                const networkDataModel = dataModels.find(
                    (d) => d.name === 'System/Network'
                );
                if (!networkDataModel) {
                    // TODO: auto generate if not found?
                    return alert(
                        "Datamodel 'System/Network' is not initialized."
                    );
                }
                localStorage['networkDataModelId'] = networkDataModel._id;

                const deviceHistoryDataModel = dataModels.find(
                    (d) => d.name === 'System/Device History'
                );
                if (!deviceHistoryDataModel) {
                    // TODO: auto generate if not found?
                    return alert(
                        "Datamodel 'System/Device History' is not initialized."
                    );
                }
                localStorage['deviceHistoryDataModelId'] =
                    deviceHistoryDataModel._id;

                await setNetwork();
                await setAssets();

                mqtt.connect().then(() => setReady(true));
            }
        } catch (error) {
            console.log(error);
            message.error('Could not authenticate profile.');
            setToken(null);
            localStorage.clear();
        }
    };

    useEffect(() => {
        if (ready) {
            mqtt.router.on(
                `${localStorage['databaseId']}/Mirror/Devices/#`,
                setAssets
            );
            mqtt.router.on(
                `${localStorage['databaseId']}/${network.dataModelId}/update`,
                setNetwork
            );
            return () => {
                mqtt.router.removeListener(
                    `${localStorage['databaseId']}/Mirror/Devices/#`,
                    setAssets
                );
                mqtt.router.removeListener(
                    `${localStorage['databaseId']}/${network.dataModelId}/update`,
                    setNetwork
                );
                mqtt.close();
            };
        }
    }, [ready]);

    useEffect(() => {
        if (token) {
            init();
        }
    }, [token]);

    return token ? (
        <Spin spinning={!ready || loading}>
            <Layout style={{ minHeight: '100vh' }}>
                <Layout.Header
                    style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <div
                        className='logo'
                        style={{ cursor: 'pointer' }}
                        role='button'
                        onClick={() =>
                            window.open('https://accumine.com', '_blank')
                        }>
                        <img src={LogoImage} />
                    </div>
                    <Menu
                        theme='light'
                        mode='horizontal'
                        selectedKeys={[pathname.split('/')[1]]}>
                        <Menu.Item key='gateways'>
                            <Link to={'/gateways'}>
                                Gateways
                                {network.roots.some(
                                    (r) =>
                                        r.state.v.state ===
                                            GatewayState.UNHEALTHY ||
                                        r.state.v.state === GatewayState.OFFLINE
                                ) && (
                                    <Tooltip title='Some Gateways need attention'>
                                        {' '}
                                        <Badge color='red' />
                                    </Tooltip>
                                )}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='sensorbots'>
                            <Link to='/sensorbots?page=1'>
                                SensorBots
                                {network.nodes
                                    .filter((n) => !n.hide.v)
                                    .some(
                                        (n) =>
                                            n.state.v.state ===
                                                NodeState.UNHEALTHY ||
                                            n.state.v.state ===
                                                NodeState.OFFLINE ||
                                            n.state.v.state ===
                                                NodeState.UNREACHABLE ||
                                            n.state.v.state ===
                                                NodeState.UNSYNCABLE
                                    ) && (
                                    <Tooltip title='Some SensorBots need attention'>
                                        {' '}
                                        <Badge color='red' />
                                    </Tooltip>
                                )}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='assets'>
                            <Link to='/assets'>Assets</Link>
                        </Menu.Item>
                        <Menu.Item
                            key='/accumine-cloud'
                            style={{ marginLeft: 'auto' }}
                            onClick={() => {
                                window.open(
                                    `${process.env.REACT_APP_CLOUD}/login?token=${localStorage['token']}`,
                                    '_blank'
                                );
                            }}>
                            Accumine Cloud
                        </Menu.Item>
                        <Menu.Item
                            key='sign-out'
                            icon={<LogoutOutlined />}
                            onClick={() => {
                                localStorage.clear();
                                setToken(null);
                            }}>
                            Sign Out
                        </Menu.Item>
                    </Menu>
                </Layout.Header>

                {ready && (
                    <Layout.Content
                        className='site-layout'
                        style={{ padding: '0 50px', marginTop: 80 }}>
                        <Routes>
                            <Route
                                path='/'
                                element={<Navigate replace to='/gateways' />}
                            />
                            <Route
                                path='/gateways'
                                element={
                                    <Gateways mqtt={mqtt} network={network} />
                                }>
                                <Route
                                    path=':registeredId'
                                    element={
                                        <GatewayDetails
                                            network={network}
                                            mqtt={mqtt}
                                        />
                                    }>
                                    <Route
                                        path='diagnostics'
                                        element={
                                            <GatewayDiagnostics
                                                network={network}
                                                mqtt={mqtt}
                                            />
                                        }
                                    />
                                    <Route
                                        path='history'
                                        element={
                                            <GatewayHistory
                                                network={network}
                                                mqtt={mqtt}
                                            />
                                        }
                                    />
                                </Route>
                                <Route
                                    path='create'
                                    element={
                                        <AddGatewayModal network={network} />
                                    }
                                />
                                <Route
                                    path='subscriptions'
                                    element={
                                        <ShowSubscriptions network={network} />
                                    }
                                />
                            </Route>
                            <Route
                                path='/sensorbots'
                                element={
                                    <SensorBots
                                        mqtt={mqtt}
                                        network={network}
                                        assets={assets}
                                    />
                                }
                            />
                            <Route
                                path='/sensorbots/create'
                                element={
                                    <AddSensorBotModal network={network} />
                                }
                            />
                            <Route
                                path='/sensorbots/:nodeId/configuration'
                                element={
                                    <SensorBotDetails
                                        network={network}
                                        assets={assets}
                                    />
                                }>
                                <Route
                                    path='diagnostics'
                                    element={
                                        <SensorBotDiagnostics
                                            network={network}
                                        />
                                    }
                                />
                                <Route
                                    path='history'
                                    element={
                                        <SensorBotHistory
                                            network={network}
                                            assets={assets}
                                            mqtt={mqtt}
                                        />
                                    }
                                />
                                <Route
                                    path='note'
                                    element={<AddNewNote network={network} />}
                                />
                            </Route>
                            <Route
                                path='/assets'
                                element={
                                    <Assets
                                        mqtt={mqtt}
                                        network={network}
                                        assets={assets}
                                    />
                                }>
                                <Route
                                    path=':deviceId'
                                    element={
                                        <AssetDetails
                                            mqtt={mqtt}
                                            assets={assets}
                                            network={network}
                                        />
                                    }>
                                    <Route
                                        path='edit'
                                        element={
                                            <AssetModal
                                                assets={assets}
                                                network={network}
                                            />
                                        }
                                    />
                                </Route>
                                <Route
                                    path='create'
                                    element={
                                        <AssetModal
                                            assets={assets}
                                            network={network}
                                        />
                                    }
                                />
                            </Route>
                        </Routes>
                    </Layout.Content>
                )}
            </Layout>
        </Spin>
    ) : (
        <Login
            onLogin={() => {
                setToken(localStorage['token']);
            }}
        />
    );
};

export default App;
