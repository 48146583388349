import {
  NetworkNodeTemplate,
  NetworkTemplate,
  PinConfiguration,
} from '@accumine/xbee-network-manager/types';
import {TinyLine, Line} from '@ant-design/charts';
import {
  CopyTwoTone,
  EditOutlined,
  ExclamationCircleOutlined,
  ExpandOutlined,
  PlusCircleOutlined,
  ScissorOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Modal,
  Tag,
  Form,
  Radio,
  InputNumber,
  Row,
  Select,
  Switch,
  message,
  Spin,
  Tooltip,
  Typography,
  Space,
  AutoComplete,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import {useEffect, useState} from 'react';
import {clone, isEmpty, omit} from 'ramda';
import {Measurement} from '@accumine/xbee-network-manager/types';
import {addEvent, updateSensorBot} from '../../util/networkManager';
import {useInterval} from '../../util/useInterval';
import {ChartData} from '../../interfaces';
import {MAX_FPS} from '@accumine/xbee-network-manager/const';
import {difference, toMillis, wrap} from '@accumine/xbee-network-manager/util';

dayjs.extend(localizedFormat);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

interface PinConfigurationForm extends PinConfiguration {
  countSampleThresholdUnits: number;
  uniformSampleThresholdUnits: number;
}

const Suggestions = [
  {value: 'Bender Active'},
  {value: 'Press RAM Up'},
  {value: 'Press Motor Current'},
  {value: 'Spindle Drive Current'},
  {value: 'Coolant Tank Temperature'},
  {value: 'PLC In-Cycle'},
  {value: 'PLC Part Count'},
  {value: 'PLC Downtime'},
  {value: 'PLC Manual Mode'},
  {value: 'PLC Program Start'},
  {value: 'PLC Program End'},
  {value: 'PLC Conveyor Running'},
  {value: 'E-Stop'},
  {value: 'Emergency Stop'},
  {value: 'Fault Code ###'},
  {value: 'Alarm'},
  {value: 'Accelerometer X Axis'},
  {value: 'Accelerometer Y Axis'},
  {value: 'Accelerometer Z Axis'},
];

const {Option} = Select;

const PinTile = ({
  network,
  sensorBot,
  pinIndex,
}: {
  network: NetworkTemplate;
  sensorBot: NetworkNodeTemplate;
  pinIndex: string;
}) => {
  const [form] = Form.useForm();
  const [waitingForSync, setWaitingForSync] = useState(false);
  const [trafficError, setTrafficError] = useState(false);
  const [fpsError, setFPSError] = useState(false);
  const [configureModalOpen, setConfigureModalOpen] = useState(false);
  const [expandedDataModalOpen, setExpandedDataModelOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [raw, setRaw] = useState(false);
  const [enableFetching, setEnableFetching] = useState(
    sensorBot.pins[pinIndex].v.pinEnable
  );
  const [errorFetching, setErrorFetching] = useState(false);
  const [chartData, setChartData] = useState<ChartData>({
    data: [],
    last: 0,
    color: '',
    tooltip: '',
    unit: sensorBot.pins[pinIndex].v.pinNumber <= 4 ? 'V' : 'mA',
    conversion:
      sensorBot.pins[pinIndex].v.pinNumber <= 4 ? 24 / (2 << 14) : 20 / (2 << 14),
    lastFetch: dayjs().utc().valueOf(),
  });

  let maxValue = sensorBot.version.v.startsWith('3') ? 5 : (sensorBot.pins[pinIndex].v.pinNumber <= 4 ? 24 : 20);
  let minValue = sensorBot.version.v.startsWith('3') ? 0 : (sensorBot.pins[pinIndex].v.pinNumber <= 4 ? 0 : 4);
  let displayUnit = sensorBot.version.v.startsWith('3') ? 'V' : (sensorBot.pins[pinIndex].v.pinNumber <= 4 ? 'V' : 'mA');
  let bits = sensorBot.version.v.startsWith('3') ? 9 : 14;
  let convert = maxValue / (2 << bits);

  let content: PinConfigurationForm = {
    ...{
      countSampleThresholdUnits:
        sensorBot.pins[pinIndex].v.countSampleThreshold * convert,
      uniformSampleThresholdUnits:
        sensorBot.pins[pinIndex].v.uniformSampleThreshold * convert,
    },
    ...clone(sensorBot.pins[pinIndex].v),
  };
  const [formContent, setFormContent] = useState<PinConfigurationForm>(content);

  const toFPS = (rate: number, unit: string, number: number) => {
    if (rate && unit && number) {
      return 1 / ((toMillis(rate, unit) * number) / 1000);
    } else {
      return 0;
    }
  };
  const getConversion = (data: Measurement[]) => {
    let conversion: number = convert;
    let unit: string;
    if (sensorBot.pins[pinIndex].v.pinLogic === 'uniform_sampling') {
      if (sensorBot.pins[pinIndex].v.pinNumber <= 4 || sensorBot.version.v.startsWith('3')) {
        unit = 'V';
        let units = data.map((d) => (d.value / d.gain) * conversion);
        if (Math.max(...units) <= 0.1) {
          unit = 'mV';
          conversion *= 1000;
        }
      } else {
        unit = 'mA';
      }
    } else {
      conversion = 1;
      unit = ' Counts';
    }

    return [conversion, unit];
  };
  const submit = async () => {
    setLoading(true);
    let cont = true;

    try {
      await form.validateFields();
    } catch (error) {
      message.error(`Errors found.`);
      setLoading(false);
      setConfigureModalOpen(true);
      return;
    }

    try {
      let fps = toFPS(
        formContent.customSampleRate,
        formContent.customSampleRateUnit,
        formContent.customSampleNumber
      );
      if (fps < 1 / 60) {
        cont = await new Promise((resolve) => {
          let duration = dayjs.duration(1 / fps, 'seconds').humanize();
          if (duration.startsWith('an ') || duration.startsWith('a ')) {
            duration = 'once ' + duration;
          } else {
            duration = 'every ' + duration;
          }
          Modal.confirm({
            title: 'Are you sure?',
            icon: <ExclamationCircleOutlined />,
            content: (
              <Typography.Text>
                <p>{`These settings only publish data ${duration}`}</p>
              </Typography.Text>
            ),
            onOk() {
              resolve(true);
            },
            onCancel() {
              resolve(false);
            },
            centered: true,
          });
        });
      }
    } catch (error) {
      console.log(error);
    }

    if (cont) {
      try {
        let synchronize = false;
        if (
          !isEmpty(
            difference(
              omit(['pinDisplay'], sensorBot.pins[pinIndex].v),
              omit(
                [
                  'pinDisplay',
                  'uniformSampleThresholdUnits',
                  'countSampleThresholdUnits',
                ],
                formContent
              )
            )
          )
        ) {
          synchronize = true;
        }

        let updated = omit(
          ['uniformSampleThresholdUnits', 'countSampleThresholdUnits'],
          formContent
        ) as PinConfiguration;
        updated.synchronized = !synchronize;
        sensorBot.pins[pinIndex] = wrap(updated, 'cloud');
        sensorBot.fps = wrap(Object.values(sensorBot.pins)
          .filter((pin) => pin.v.pinEnable)
          .reduce((fps, pin) => {
            return (
              fps +
              toFPS(
                pin.v.customSampleRate,
                pin.v.customSampleRateUnit,
                pin.v.customSampleNumber
              )
            );
          }, 0), 'cloud');
        await updateSensorBot(sensorBot);
        await addEvent({
          userId: localStorage.getItem('username') as string,
          deviceId: sensorBot.nodeId.v,
          type: 'event',
          description: `User modified datastream ${parseInt(pinIndex[1]) + 1}.`,
          timestamp: dayjs().utc().valueOf(),
        });
        message.success(`Changes saved.`);
        setConfigureModalOpen(false);
      } catch (error) {
        message.error(`Could not save changes: ${error}`);
      }
    }
    setLoading(false);
  };
  const fetchData = async (): Promise<{error: boolean; data: ChartData}> => {
    let response = {
      data: {...chartData},
      error: false,
    };
    try {
      let {data}: {data: Measurement[]} = await axios.post(
        `${process.env.REACT_APP_API}/v2/raw/sensorbot-data`,
        {
          timestamp: chartData.lastFetch,
          nodeId: sensorBot.nodeId.v,
          name: pinIndex,
        }
      );
      if (data.length > 0) {
        let [conversion, unit] = getConversion(data);
        let color = '';
        let tooltip = '';
        let last = data[data.length - 1].value / data[data.length - 1].gain;

        if (sensorBot.pins[pinIndex].v.pinLogic === 'uniform_sampling') {
          if (last < -100) {
            color = 'red';
            tooltip = `IN${sensorBot.pins[pinIndex].v.pinNumber} has a misconfigured input signal.`;
          } else if (last > -100 && last < 0) {
            color = 'yellow';
            tooltip = `IN${sensorBot.pins[pinIndex].v.pinNumber} is disconnected.`;
          } else {
            color = 'green';
            tooltip = `IN${sensorBot.pins[pinIndex].v.pinNumber} is connected to a signal.`;
          }
        } else {
          color = '';
          tooltip = '';
        }

        response.data = {
          data: chartData.data.concat(data).slice(-100),
          last: Math.round(last * (conversion as number) * 1000) / 1000,
          color: color,
          tooltip: tooltip,
          conversion: conversion as number,
          unit: unit as string,
          lastFetch: data[data.length - 1].timestamp,
        };
      }
      if (errorFetching) {
        response.error = false;
      }
    } catch (error) {
      console.log(error);
      response.error = true;
    }
    return response;
  };
  const checkTraffic = () => {
    let gateway = network.roots.find(
      (root) => root.registeredId.v === sensorBot.gatewayId.v
    );
    if (gateway) {
      let gatewayFPS = gateway.fps.v;
      let newFPS = 0;
      let oldFPS = 0;
      if (formContent.pinEnable) {
        newFPS += toFPS(
          formContent.customSampleRate,
          formContent.customSampleRateUnit,
          formContent.customSampleNumber
        );
      }
      if (sensorBot.pins[pinIndex].v.pinEnable) {
        oldFPS += toFPS(
          sensorBot.pins[pinIndex].v.customSampleRate,
          sensorBot.pins[pinIndex].v.customSampleRateUnit,
          sensorBot.pins[pinIndex].v.customSampleNumber
        );
      }
      if (gatewayFPS - oldFPS + newFPS > MAX_FPS) {
        setTrafficError(true);
      } else {
        setTrafficError(false);
      }
      if (sensorBot.rtt.v && sensorBot.rtt.v !== -1) {
        if (newFPS > 1000 / sensorBot.rtt.v) {
          setFPSError(true);
        } else {
          setFPSError(false);
        }
      } else {
        setFPSError(false);
      }
    } else {
      setTrafficError(false);
    }
  };
  const copyPinSettings = () => {
    sessionStorage.setItem(
      'pinSettings',
      JSON.stringify({
        resolution: formContent.resolution,
        pinLogic: formContent.pinLogic,
        customSampleRate: formContent.customSampleRate,
        customSampleRateUnit: formContent.customSampleRateUnit,
        customSampleNumber: formContent.customSampleNumber,
        uniformLogic: formContent.uniformLogic,
        uniformSampleThreshold: formContent.uniformSampleThreshold,
        uniformSampleThresholdUnits: formContent.uniformSampleThresholdUnits,
        countLogic: formContent.countLogic,
        countSampleThreshold: formContent.countSampleThreshold,
        countSampleThresholdUnits: formContent.countSampleThresholdUnits,
      })
    );
    message.info('Copied.');
  };
  const pastePinSettings = () => {
    let pinSettings = JSON.parse(
      sessionStorage.getItem('pinSettings') as string
    );
    if (pinSettings) {
      formContent.resolution = pinSettings.resolution;
      formContent.pinLogic = pinSettings.pinLogic;
      formContent.customSampleRate = pinSettings.customSampleRate;
      formContent.customSampleRateUnit = pinSettings.customSampleRateUnit;
      formContent.customSampleNumber = pinSettings.customSampleNumber;
      formContent.uniformLogic = pinSettings.uniformLogic;
      formContent.uniformSampleThreshold = pinSettings.uniformSampleThreshold;
      formContent.uniformSampleThresholdUnits =
        pinSettings.uniformSampleThresholdUnits;
      formContent.countLogic = pinSettings.countLogic;
      formContent.countSampleThreshold = pinSettings.countSampleThreshold;
      formContent.countSampleThresholdUnits =
        pinSettings.countSampleThresholdUnits;
      form.setFieldsValue({...formContent});
      setFormContent(formContent);
      message.info('Pasted.');
    } else {
      message.info('Nothing to paste.');
    }
  };

  useEffect(() => {
    checkTraffic();
  }, [formContent]);

  useEffect(() => {
    setWaitingForSync(
      !(sensorBot.vref.v.synchronized && sensorBot.pins[pinIndex].v.synchronized)
    );
  }, [sensorBot]);

  useEffect(() => {
    if (!waitingForSync) {
      setChartData({
        data: [],
        last: chartData.last,
        color: chartData.color,
        tooltip: chartData.tooltip,
        conversion: chartData.conversion,
        unit: chartData.unit,
        lastFetch: dayjs().utc().valueOf(),
      });
      setEnableFetching(sensorBot.pins[pinIndex].v.pinEnable);
    }
  }, [waitingForSync]);

  useInterval(async () => {
    try {
      if (enableFetching) {
        const {error, data} = await fetchData();
        setErrorFetching(error);
        if (!error) {
          setChartData(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, 5000);

  return (
    <>
      {sensorBot.pins[pinIndex].v.pinEnable ? (
        <Col
          span="6"
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Card
            title={
              <Tooltip
                title={sensorBot.pins[pinIndex].v.pinDisplay || 'Unnamed Stream'}
              >
                <Typography.Text>
                  {sensorBot.pins[pinIndex].v.pinDisplay || 'Unnamed Stream'}
                </Typography.Text>
              </Tooltip>
            }
            extra={
              chartData.data.length ? (
                <Space>
                  <Tooltip title="Data publishes per second.">
                    <Tag style={{float: 'left'}}>
                      PPS:{' '}
                      {Math.round(
                        toFPS(
                          sensorBot.pins[pinIndex].v.customSampleRate,
                          sensorBot.pins[pinIndex].v.customSampleRateUnit,
                          sensorBot.pins[pinIndex].v.customSampleNumber
                        ) * 1000
                      ) / 1000}
                    </Tag>
                  </Tooltip>
                  <Tooltip title={chartData.tooltip}>
                    <Tag style={{float: 'right'}} color={chartData.color}>
                      {`${chartData.last}${chartData.unit}`}
                    </Tag>
                  </Tooltip>
                </Space>
              ) : undefined
            }
            actions={[
              <Tooltip title={sensorBot.locked.v ? 'SensorBot is locked' : ''}>
                <Button
                  disabled={sensorBot.locked.v}
                  size="small"
                  key={`pin${pinIndex}-edit`}
                  icon={<EditOutlined />}
                  onClick={() => {
                    checkTraffic();
                    setConfigureModalOpen(true);
                  }}
                >
                  Edit
                </Button>
              </Tooltip>,
              <Button
                size="small"
                key={`pin${pinIndex}-edit`}
                icon={<ExpandOutlined />}
                onClick={() => setExpandedDataModelOpen(true)}
                disabled={chartData.data.length === 0}
              >
                Expand
              </Button>,
            ]}
          >
            <div style={{textAlign: 'center'}}>
              {chartData.data.length > 0 ? (
                <TinyLine
                  animation={false}
                  height={60}
                  autoFit={false}
                  data={chartData.data.map(
                    (d) =>
                      Math.round(
                        (d.value / d.gain) * chartData.conversion * 1000
                      ) / 1000
                  )}
                  smooth={true}
                />
              ) : (
                <Spin tip="Waiting for data" />
              )}
            </div>
          </Card>
        </Col>
      ) : (
        <Col
          span="6"
          style={{padding: 10, textAlign: 'center', verticalAlign: 'middle'}}
        >
          <Card title={`Datastream ${parseInt(pinIndex[1]) + 1}`}>
            <Tooltip title={sensorBot.locked.v ? 'SensorBot is locked' : ''}>
              <Button
                disabled={sensorBot.locked.v}
                type="default"
                icon={<PlusCircleOutlined />}
                onClick={() => setConfigureModalOpen(true)}
              >
                Configure
              </Button>
            </Tooltip>
          </Card>
        </Col>
      )}
      {configureModalOpen && (
        <Modal
          visible={true}
          title={
            <Row align="middle">
              <Col span={24}>
                <Typography.Title
                  style={{float: 'left'}}
                  level={4}
                >{`Configure Datastream ${
                  parseInt(pinIndex[1]) + 1
                }`}</Typography.Title>
                <Space
                  style={{marginLeft: 20, float: 'left'}}
                  align="center"
                  size={0}
                >
                  <Tooltip title="Copy pin settings.">
                    <Button
                      type="text"
                      icon={<ScissorOutlined />}
                      onClick={() => copyPinSettings()}
                    />
                  </Tooltip>
                  <Tooltip title="Paste pin settings.">
                    <Button
                      type="text"
                      icon={<CopyTwoTone />}
                      onClick={() => pastePinSettings()}
                    />
                  </Tooltip>
                </Space>
              </Col>
            </Row>
          }
          onCancel={() => {
            setConfigureModalOpen(false);
          }}
          okText="Save"
          okButtonProps={{loading, disabled: trafficError || fpsError}}
          onOk={submit}
        >
          <Form
            form={form}
            initialValues={formContent}
            preserve={false}
            layout="vertical"
            onValuesChange={(
              _changedValues,
              allValues: PinConfigurationForm
            ) => {
              allValues.pinId = formContent.pinId;
              if (allValues.resolution === 'high') {
                allValues.customSampleNumber = 10;
                allValues.customSampleRate = 100;
                allValues.customSampleRateUnit = 'milli';
              } else if (allValues.resolution === 'medium') {
                allValues.customSampleNumber = 50;
                allValues.customSampleRate = 100;
                allValues.customSampleRateUnit = 'milli';
              } else if (allValues.resolution === 'low') {
                allValues.customSampleNumber = 200;
                allValues.customSampleRate = 100;
                allValues.customSampleRateUnit = 'milli';
              } else {
                if (!allValues.customSampleNumber) {
                  allValues.customSampleNumber =
                    sensorBot.pins[pinIndex].v.customSampleNumber;
                }
                if (!allValues.customSampleRate) {
                  allValues.customSampleRate =
                    sensorBot.pins[pinIndex].v.customSampleRate;
                }
                if (!allValues.customSampleRateUnit) {
                  allValues.customSampleRateUnit =
                    sensorBot.pins[pinIndex].v.customSampleRateUnit;
                }
              }
              allValues.countSampleThreshold = Math.round(
                allValues.countSampleThresholdUnits / convert
              );
              allValues.uniformSampleThreshold = Math.round(
                allValues.uniformSampleThresholdUnits / convert
              );
              setFormContent(allValues);
            }}
          >
            <Form.Item
              name="pinEnable"
              label="Enable Stream"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="pinNumber"
              label="Input Pin"
              rules={[{required: true, message: 'Input Pin is required.'}]}
              hidden={!formContent.pinEnable}
            >
              <Select>
                <Select.OptGroup key="0-24V" label="0-24V (Voltage)">
                  <Select.Option value={1}>IN1</Select.Option>
                  <Select.Option value={2}>IN2</Select.Option>
                  <Select.Option value={3}>IN3</Select.Option>
                  <Select.Option value={4}>IN4</Select.Option>
                </Select.OptGroup>
                <Select.OptGroup key="4-20mA" label="4-20mA (Current)">
                  <Select.Option value={5}>IN5</Select.Option>
                  <Select.Option value={6}>IN6</Select.Option>
                  <Select.Option value={7}>IN7</Select.Option>
                  <Select.Option value={8}>IN8</Select.Option>
                </Select.OptGroup>
              </Select>
            </Form.Item>
            <Form.Item
              name="pinDisplay"
              label="Display Name"
              rules={[
                {required: true, message: 'Display Name is required.'},
                () => ({
                  validator(_, value) {
                    let p = Object.values(sensorBot.pins).filter(p => p.v.pinId !== pinIndex).find(p => p.v.pinDisplay === value);
                    if (!p) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(new Error(`Datastream ${parseInt(p.v.pinId[1]) + 1} already has this name.`));
                    }
                  },
                }),
              ]}
              hidden={!formContent.pinEnable}
            >
              <AutoComplete
                options={Suggestions}
                placeholder={`E.g. ${Suggestions[0].value}`}
                filterOption={(inputValue, option) =>
                  option
                    ? option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    : false
                }
              />
            </Form.Item>
            <Form.Item
              name="pinLogic"
              label="Sampling Logic"
              hidden={!formContent.pinEnable}
            >
              <Radio.Group>
                <Radio.Button value="uniform_sampling">
                  Periodic Measurements
                </Radio.Button>
                <Radio.Button value="counting">Count Events</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="resolution"
              label="Resolution:"
              tooltip="How often to take measurements."
              rules={[
                {
                  required: true,
                  message: 'Resolution setting is required.',
                },
              ]}
              hidden={!formContent.pinEnable}
            >
              <Radio.Group>
                <Radio.Button value="low">Low</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="high">High</Radio.Button>
                <Radio.Button value="custom">Custom</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Row>
              <Col span={12}>
                <Form.Item
                  name={
                    formContent.resolution === 'custom'
                      ? 'customSampleRate'
                      : undefined
                  }
                  label="Measurement Rate"
                  tooltip="How often to take a measurement."
                  rules={[
                    {required: true, message: 'Measurement rate is required.'},
                  ]}
                  hidden={!formContent.pinEnable}
                >
                  <InputNumber
                    value={formContent.customSampleRate}
                    disabled={formContent.resolution !== 'custom'}
                    min={0.001}
                    style={{width: '200px'}}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name={
                    formContent.resolution === 'custom'
                      ? 'customSampleRateUnit'
                      : undefined
                  }
                  label="Measurement Rate Unit"
                  tooltip="Unit of the measurement rate."
                  rules={[
                    {
                      required: true,
                      message: 'Measurement rate unit is required.',
                    },
                  ]}
                  hidden={!formContent.pinEnable}
                >
                  <Select
                    value={formContent.customSampleRateUnit}
                    disabled={formContent.resolution !== 'custom'}
                    style={{width: '200px'}}
                  >
                    <Option value="micro">microsecond(s)</Option>
                    <Option value="milli">millisecond(s)</Option>
                    <Option value="second">second(s)</Option>
                    <Option value="minute">minute(s)</Option>
                    <Option value="hour">hour(s)</Option>
                    <Option value="day">day(s)</Option>
                    <Option value="month">month(s)</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name={
                    formContent.resolution === 'custom'
                      ? 'customSampleNumber'
                      : undefined
                  }
                  label="Number of Measurements"
                  tooltip="How many measurements to collect before processing."
                  rules={[
                    {
                      required: true,
                      message: 'Measurement number is required.',
                    },
                  ]}
                  hidden={!formContent.pinEnable}
                >
                  <InputNumber
                    value={formContent.customSampleNumber}
                    disabled={formContent.resolution !== 'custom'}
                    addonAfter="samples"
                    precision={0}
                    min={1}
                    style={{width: '200px'}}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="uniformSampleThresholdUnits"
                  label="Publish Threshold"
                  tooltip={`Only publish a result if the value has changed by the threshold ${
                    formContent.pinNumber <= 4 ? '(0-24V)' : '(4-20mA)'
                  }`}
                  rules={[{required: true, message: 'Threshold is required.'}]}
                  hidden={
                    !(
                      formContent.pinEnable &&
                      formContent.pinLogic === 'uniform_sampling'
                    )
                  }
                >
                  <InputNumber
                    precision={3}
                    min={0}
                    max={maxValue}
                    style={{width: '200px'}}
                    addonAfter={displayUnit}
                  />
                </Form.Item>
                <Form.Item
                  name="countSampleThresholdUnits"
                  label="Event Threshold"
                  tooltip={`Count an event if the signal crosses the set threshold.`}
                  rules={[{required: true, message: 'Threshold is required.'}]}
                  hidden={
                    !(
                      formContent.pinEnable &&
                      formContent.pinLogic === 'counting'
                    )
                  }
                >
                  <InputNumber
                    precision={3}
                    min={minValue}
                    max={maxValue}
                    style={{width: '200px'}}
                    addonAfter={displayUnit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="uniformLogic"
                  label="Processing"
                  tooltip={`After taking ${
                    formContent.customSampleNumber
                  } sample(s), calculate the ${formContent.uniformLogic.toLowerCase()} and publish it to the Cloud.`}
                  rules={[
                    {required: true, message: 'Processing logic is required.'},
                  ]}
                  hidden={
                    !(
                      formContent.pinEnable &&
                      formContent.pinLogic === 'uniform_sampling'
                    )
                  }
                >
                  <Radio.Group disabled={formContent.resolution !== 'custom'}>
                    <Radio.Button value="average">Average</Radio.Button>
                    <Radio.Button value="minimum">Minimum</Radio.Button>
                    <Radio.Button value="maximum">Maximum</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="countLogic"
                  label="Count Logic"
                  tooltip={`Count an event if the signal goes above or below the threshold`}
                  rules={[
                    {
                      required: true,
                      message: 'Counting logic is required.',
                    },
                  ]}
                  hidden={
                    !(
                      formContent.pinEnable &&
                      formContent.pinLogic === 'counting'
                    )
                  }
                >
                  <Radio.Group>
                    <Radio.Button value="above">Above</Radio.Button>
                    <Radio.Button value="below">Below</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {formContent.pinEnable && (
                <Typography.Text>{`Maximum publishes per second for this device: ${
                  sensorBot.rtt.v && sensorBot.rtt.v !== -1 ? Math.round((1000 / sensorBot.rtt.v) * 100) / 100 : 'Unknown'
                }`}</Typography.Text>
              )}
            </Row>
            <Row>
              {formContent.pinEnable && (
                <Typography.Text>{`Publishes per second for this datastream: ${toFPS(
                  formContent.customSampleRate,
                  formContent.customSampleRateUnit,
                  formContent.customSampleNumber
                )}`}</Typography.Text>
              )}
            </Row>
            <Row>
              {formContent.pinEnable && fpsError && (
                <Row>
                  <Typography.Text type="danger">
                    These settings would cause too much traffic for this device.
                  </Typography.Text>
                  <Typography.Text type="danger">
                    Change the measurement rate, number, or unit.
                  </Typography.Text>
                </Row>
              )}
            </Row>
            <Row>
              {formContent.pinEnable && (
                <Typography.Text>{`Network load contribution: ${
                  Math.round(
                    (toFPS(
                      formContent.customSampleRate,
                      formContent.customSampleRateUnit,
                      formContent.customSampleNumber
                    ) /
                      MAX_FPS) *
                      100 *
                      100
                  ) / 100
                }%`}</Typography.Text>
              )}
            </Row>
            <Row>
              {formContent.pinEnable && trafficError && (
                <Row>
                  <Typography.Text type="danger">
                    These settings would cause too much network traffic for the
                    gateway.
                  </Typography.Text>
                  <Typography.Text type="danger">
                    Change the measurement rate, number, or unit.
                  </Typography.Text>
                </Row>
              )}
            </Row>
          </Form>
        </Modal>
      )}

      {expandedDataModalOpen && (
        <Modal
          width={750}
          visible={true}
          title={
            <Space>
              <Typography.Text>
                {sensorBot.pins[pinIndex].v.pinDisplay || 'Unnamed Stream'}
              </Typography.Text>
              <Switch
                checkedChildren="Raw"
                unCheckedChildren="Raw"
                onChange={(checked) => setRaw(checked)}
              />
            </Space>
          }
          onCancel={() => setExpandedDataModelOpen(false)}
          cancelButtonProps={{style: {display: 'none'}}}
          okButtonProps={{style: {display: 'none'}}}
        >
          <Line
            animation={false}
            padding="auto"
            xField="timestamp"
            yField="value"
            yAxis={{
              label: {
                formatter: (v) => `${v}${raw ? '' : chartData.unit}`,
              },
            }}
            data={chartData.data.map((d) => {
              let v = d.value * (raw ? 1 : chartData.conversion / d.gain);
              return {
                value: v,
                timestamp: dayjs(d.timestamp).format('LTS'),
              };
            })}
          />
        </Modal>
      )}
    </>
  );
};

export default PinTile;
