import {NetworkTemplate} from '@accumine/xbee-network-manager/types';
import { wrap } from '@accumine/xbee-network-manager/util';
import {
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Result,
  Row,
  Select,
  Switch,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import {useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {AccumineCloudAsset} from '../../interfaces';
import {addEvent, updateSensorBot} from '../../util/networkManager';

type AccumineCloudAssetForm = AccumineCloudAsset & {
  downtimeCodes?: boolean;
  shifts?: string[];
};

const AssetModal = ({
  assets,
  network,
}: {
  assets: AccumineCloudAsset[];
  network: NetworkTemplate;
}) => {
  const params = useParams();
  const asset = assets.find((a) => a.deviceId === params.deviceId);
  return (
    <>
      {params.deviceId && asset && (
        <AssetModalChild assets={assets} asset={asset} network={network} />
      )}
      {!params.deviceId && (
        <AssetModalChild assets={assets} network={network} />
      )}
      {(!params.deviceId || (params.deviceId && !asset)) && (
        <Col span="19">
          <Result
            status="404"
            title="Asset doesn't exist"
            subTitle="The asset you've selected does not exist. Please select another."
          />
        </Col>
      )}
    </>
  );
};

const AssetModalChild = ({
  assets,
  asset,
  network,
}: {
  assets: AccumineCloudAsset[];
  asset?: AccumineCloudAsset;
  network: NetworkTemplate;
}) => {
  const navigate = useNavigate();
  let [params] = useSearchParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    setLoading(true);
    let _asset: AccumineCloudAssetForm;
    try {
      _asset = await form.validateFields();
    } catch (error) {
      message.error(`Invalid form entry`);
      setLoading(false);
      return;
    }

    let _id = asset?._id,
      deviceId = asset?.deviceId;

    const hourlyTarget = _asset.fixedHourlyTarget,
      downtimeCodes = _asset.downtimeCodes;

    delete _asset.fixedHourlyTarget;
    delete _asset.downtimeCodes;
    delete _asset.shifts;

    try {
      if (!asset) {
        // new asset
        if (assets.find((a) => a.name === _asset.name)) {
          message.error('An asset with this name already exists.');
          setLoading(false);
          return;
        }

        _asset.oeeTargets = {
          benchmark: 100,
          realTime: 100,
        };

        let path = `${process.env.REACT_APP_API}/v1/devices/register?autoAddOneToOneFlow=true&`;
        if (downtimeCodes) {
          path += 'autoAddToReasonCodes=true';
        }

        const {data}: {data: AccumineCloudAsset} = await axios.post(
          path,
          _asset
        );
        _id = data._id;
        deviceId = data._id;
      } else {
        // editing asset
        if (
          assets.find(
            (a) => a.deviceId !== asset.deviceId && a.name === _asset.name
          )
        ) {
          message.error('An asset with this name already exists.');
          setLoading(false);
          return;
        }

        const rootPath = `${process.env.REACT_APP_API}/v1/devices/${_id}/`;

        if (_asset.name !== asset.name) {
          await axios.patch(`${rootPath}rename`, {name: _asset.name});
        }

        await axios.patch(`${rootPath}downtime-threshold`, {
          downtimeThresholdSeconds: _asset.downtimeThresholdSeconds,
        });

        await axios.patch(`${rootPath}groups`, {groups: _asset.groups});
        await axios.patch(`${rootPath}production-unit`, {
          productionUnit: _asset.productionUnit,
        });
        await axios.patch(`${rootPath}oee-settings`, {
          oeePartType: _asset.oeePartType,
          oeeRejectType: _asset.oeeRejectType,
          oeeTargetType: _asset.oeeTargetType,
        });
      }

      if (hourlyTarget && hourlyTarget > 0) {
        try {
          await axios.post(`${process.env.REACT_APP_API}/v2/tablets/data`, [
            {
              nodeId: deviceId,
              name: 'Static Hourly Target',
              value: hourlyTarget,
              timestamp: dayjs().valueOf(),
            },
          ]);
        } catch (error) {
          console.log(error);
          return alert('Could not set hourly target');
        }
      }
      let sensorBot = network.nodes.find(
        (n) => n.nodeId.v === params.get('sensorBotId')
      );
      if (sensorBot) {
        sensorBot.deviceId = wrap(deviceId as string, 'cloud');
        await updateSensorBot(sensorBot);
        await addEvent({
          userId: localStorage.getItem('username') as string,
          deviceId: sensorBot.nodeId.v,
          type: 'event',
          description: `User modified the device assignment to ${deviceId}.`,
          timestamp: dayjs().utc().valueOf(),
        });
        navigate('/sensorbots?page=1');
      }
    } catch (error) {
      message.error('Could not save changes. Please try again.');
    }

    setLoading(false);
  };

  return (
    <>
      <Modal
        visible={true}
        title={asset ? asset.name : 'Add Asset'}
        cancelButtonProps={{style: {display: 'none'}}}
        okText="Submit"
        onOk={submit}
        okButtonProps={{loading}}
        onCancel={() => {
          if (params.get('sensorBotId')) {
            navigate('/sensorbots?page=1');
          } else {
            navigate(`/assets/${asset ? asset.deviceId : ''}`);
          }
        }}
      >
        <Form
          form={form}
          name="login"
          layout="vertical"
          initialValues={asset ? asset : undefined}
        >
          <Form.Item
            label="Name"
            name="name"
            initialValue={asset ? asset.name || '' : null}
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Group"
            name="groups"
            initialValue={asset ? asset.groups || [] : []}
            tooltip="Group your assets by type, cell or however assets in your plant are categorized. Adding assets to a group provides access to more comprehensive reporting tools, such as the 'Drilldown Tree'"
          >
            <Select
              mode="tags"
              maxTagCount={1}
              options={
                [
                  ...Array.from(
                    new Set(
                      assets
                        .filter((a) => a.groups)
                        .map((a) => a.groups)
                        .flat()
                    )
                  ),
                ].map((t) => {
                  return {
                    key: t,
                    value: t,
                  };
                }) as {key: string; value: string}[]
              }
            />
          </Form.Item>
          <Form.Item
            label="Reason Code Entry Delay"
            name="downtimeThresholdSeconds"
            initialValue={asset ? asset.downtimeThresholdSeconds || null : 300}
            tooltip="How long a downtime period has to be active before users are allowed to enter a reason code. (i.e. a value of 15 seconds means that users cannot enter a downtime reason until the machine has been down for longer than 15 seconds)."
            rules={[
              {
                required: true,
                message: 'A value above 0 is required',
              },
            ]}
          >
            <InputNumber addonAfter="seconds" min={1} />
          </Form.Item>

          <Form.Item
            label="Production Unit"
            name="productionUnit"
            initialValue={asset ? asset.productionUnit || '' : 'part'}
            tooltip="What type of product this asset makes. Examples include: 'part', 'mold', etc."
            rules={[
              {
                required: true,
                message: 'Production Unit is required',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Hourly Target"
            name="fixedHourlyTarget"
            initialValue={asset ? asset.fixedHourlyTarget || 0 : 0}
            tooltip="An ideal hourly target based on asset design. This target will be ignored if hourly targets are pulled from your production schedule."
          >
            <InputNumber min={1} />
          </Form.Item>

          <Row gutter={[10, 10]}>
            <Col span="8">
              <Form.Item
                label="Part Count"
                name="oeePartType"
                tooltip="Primarily for OEE, choose whether Part Count data should be collected Automatically or Manually."
                initialValue={asset ? asset.oeePartType || null : 'automatic'}
              >
                <Select
                  options={[
                    {label: 'Automatic', value: 'automatic'},
                    {label: 'Manual', value: 'manual'},
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="Rejects"
                name="oeeRejectType"
                tooltip="Primarily for OEE, choose whether bad parts / rejects data should be collected Automatically or Manually."
                initialValue={asset ? asset.oeeRejectType || null : 'manual'}
              >
                <Select
                  options={[
                    {label: 'Automatic', value: 'automatic'},
                    {label: 'Manual', value: 'manual'},
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="Hourly Target"
                name="oeeTargetType"
                tooltip="Primarily for OEE, choose whether Target data should be collected Automatically or Manually."
                initialValue={asset ? asset.oeeTargetType || null : 'manual'}
              >
                <Select
                  options={[
                    {label: 'Automatic', value: 'automatic'},
                    {label: 'Manual', value: 'manual'},
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          {!asset && (
            <Form.Item
              label="Associate with all listed downtime reason codes"
              name="downtimeCodes"
              help="Downtime Reason Codes can be managed in Accumine Cloud"
            >
              <Switch />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default AssetModal;
