import {
  NetworkNodeTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import { wrap } from '@accumine/xbee-network-manager/util';
import {
  HistoryOutlined,
  InfoCircleTwoTone,
  PlusSquareTwoTone,
} from '@ant-design/icons';
import {
  Button,
  Col,
  message,
  PageHeader,
  Result,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import SensorBotStatus from '../../components/SensorBotStatus';
import {AccumineCloudAsset} from '../../interfaces';
import {addEvent, updateSensorBot} from '../../util/networkManager';
import AssetSelector from './assetSelector';
import GatewaySelector from './gatewaySelector';
import PinTile from './PinTile';

const SensorBotDetailsChild = ({
  sensorBot,
  network,
  assets,
}: {
  sensorBot: NetworkNodeTemplate;
  network: NetworkTemplate;
  assets: AccumineCloudAsset[];
}) => {
  const navigate = useNavigate();
  const getGain = (sensorBot: NetworkNodeTemplate) => {
    if (sensorBot.version.v.startsWith('3')) {
      if (sensorBot.version.v === '3.0.0') {
        return [
          {
            label: '1x',
            value: 1,
          },
          {
            label: '4.5x',
            value: 0,
          },
        ];
      } else {
        return [
          {
            label: '1x',
            value: 99,
          },
          {
            label: '2x',
            value: 50,
          },
          {
            label: '4x',
            value: 25,
          },
          {
            label: '8x',
            value: 12,
          },
        ];
      }
    } else {
      return [
        {
          label: '1/3x',
          value: 99,
        },
        {
          label: '1x',
          value: 85,
        },
        {
          label: '2x',
          value: 71,
        },
        {
          label: '4x',
          value: 57,
        },
        {
          label: '8x',
          value: 43,
        },
        {
          label: '8x',
          value: 29,
        },
        {
          label: '32x',
          value: 15,
        },
        {
          label: '64x',
          value: 1,
        },
      ];
    }
  };
  const setGain = async (vref: any) => {
    sensorBot.vref = wrap({
      synchronized: false,
      vref: vref,
    }, 'cloud');
    await updateSensorBot(sensorBot);
    await addEvent({
      userId: localStorage.getItem('username') as string,
      deviceId: sensorBot.nodeId.v,
      type: 'event',
      description: `User modified the gain to ${
        getGain(sensorBot).find((g) => g.value === vref)?.label
      }.`,
      timestamp: dayjs().utc().valueOf(),
    });
    message.success('Gain changed.');
  };
  const [gainOptions, setGainOptions] = useState(getGain(sensorBot));

  useEffect(() => {
    setGainOptions(getGain(sensorBot));
  }, [sensorBot]);

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => navigate(-1)}
        title={
          <Typography.Text copyable={true}>
            {sensorBot.nodeId.v.toUpperCase()}
          </Typography.Text>
        }
        extra={[
          <Row
            wrap={false}
            gutter={[12, 0]}
            align="middle"
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <Col className="gutter-row" flex="auto">
              <Space size="small" align="center">
                <Typography.Text strong>Status:</Typography.Text>{' '}
                <SensorBotStatus sensorBot={sensorBot} iconSize={20} />
              </Space>
            </Col>
            <Col className="gutter-row" flex="auto">
              <Space size="small" align="center">
                <Typography.Text strong>Signal Gain:</Typography.Text>{' '}
                <Select
                  disabled={sensorBot.locked.v}
                  bordered={false}
                  defaultValue={sensorBot.vref.v.vref}
                  style={{width: 75}}
                  onChange={setGain}
                  options={gainOptions}
                />
              </Space>
            </Col>
            <Col className="gutter-row" flex="auto">
              <Space size="small" align="center">
                <Typography.Text strong>Asset:</Typography.Text>{' '}
                <AssetSelector network={network} assets={assets} sensorBot={sensorBot} />
              </Space>
            </Col>
            <Col className="gutter-row" flex="auto">
              <Space size="small" align="center">
                <Typography.Text strong>Gateway:</Typography.Text>{' '}
                <GatewaySelector network={network} sensorBot={sensorBot} />
              </Space>
            </Col>
          </Row>,
        ]}
      />
      <Col
        span={24}
        style={{
          height: window.innerHeight * 0.7,
          padding: '10px 16px',
          backgroundColor: '#fff',
          overflow: 'auto',
          border: '1px solid rgba(140, 140, 140, 0.35)',
          marginTop: 20,
        }}
      >
        <Row align="middle">
          <Col span={12}>
            <Typography.Title style={{padding: '0px 10px'}} level={2}>
              Datastreams
            </Typography.Title>
          </Col>
          <Col span={12}>
            <Space style={{float: 'right'}}>
              <Tooltip title="Show diagnostic information.">
                <Button
                  size="middle"
                  type="ghost"
                  icon={<InfoCircleTwoTone style={{fontSize: 20}} />}
                  onClick={() => navigate(`diagnostics`)}
                />
              </Tooltip>
              <Tooltip title="Add new note.">
                <Button
                  size="middle"
                  type="ghost"
                  icon={<PlusSquareTwoTone style={{fontSize: 20}} />}
                  onClick={() => navigate(`note`)}
                />
              </Tooltip>
              <Tooltip title="Show device history.">
                <Button
                  size="middle"
                  type="ghost"
                  icon={<HistoryOutlined style={{fontSize: 20}} />}
                  onClick={() => navigate(`history`)}
                />
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <Row align="middle">
          {Object.values(sensorBot.pins).map((pin) => (
            <PinTile
              network={network}
              sensorBot={sensorBot}
              pinIndex={pin.v.pinId}
            />
          ))}
        </Row>
      </Col>
      <Outlet />
    </>
  );
};

const SensorBotDetails = ({
  network,
  assets,
}: {
  network: NetworkTemplate;
  assets: AccumineCloudAsset[];
}) => {
  const params = useParams();
  const sensorBot = network.nodes.find((n) => n.nodeId.v === params.nodeId);
  return (
    <>
      {sensorBot ? (
        <SensorBotDetailsChild
          sensorBot={sensorBot}
          network={network}
          assets={assets}
        />
      ) : (
        <Result
          status="404"
          title="SensorBot doesn't exist"
          subTitle="The SensorBot you've selected does not exist. Click the button below to go back."
          extra={<Button>Go back</Button>}
        />
      )}
    </>
  );
};

export default SensorBotDetails;
