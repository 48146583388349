import { PlusSquareTwoTone, SearchOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    PageHeader,
    Result,
    Row,
    Space,
    Tooltip,
} from 'antd';
import { NetworkTemplate } from '@accumine/xbee-network-manager/types';
import { AccumineCloudAsset } from '../../interfaces';
import MQTT from '../../util/mqtt';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import SensorBotTable from './sensorbotTable';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect } from 'react';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const SensorBots = ({
    mqtt,
    network,
    assets,
}: {
    mqtt: typeof MQTT;
    network: NetworkTemplate;
    assets: AccumineCloudAsset[];
}) => {
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const handleSearch = (e: any) => {
        let params: {
            search?: string;
            page: string;
        } = {
            page: searchParams.get('page') as string,
        };
        if (e.target.value) {
            params.search = e.target.value;
        }
        setSearchParams(params);
    };

    useEffect(() => {
        if (typeof searchParams.get('page') !== 'string') {
            setSearchParams({ page: '1' });
        }
    });

    return (
        <>
            <>
                <PageHeader
                    ghost={false}
                    title={`${localStorage['databaseId']} SensorBots`}
                    extra={[
                        <Space align='center'>
                            <Input
                                key='search'
                                bordered={false}
                                style={{ width: 300 }}
                                prefix={<SearchOutlined />}
                                placeholder='Search...'
                                allowClear
                                onChange={handleSearch}
                                value={searchParams.get('search') || ''}
                            />
                            <Tooltip title='Add a sensorbot.'>
                                <Button
                                    type='default'
                                    onClick={() => navigate('create')}
                                    icon={
                                        <PlusSquareTwoTone
                                            twoToneColor='#1890ff'
                                            style={{ fontSize: '20px' }}
                                        />
                                    }
                                />
                            </Tooltip>
                        </Space>,
                    ]}
                />
                {network.nodes.length === 0 &&
                    searchParams.get('search') === '' && (
                        <Result
                            title='No SensorBots Found'
                            subTitle='SensorBots are automatically added when discovered'
                            extra={[]}
                        />
                    )}

                {!(
                    network.nodes.length === 0 &&
                    searchParams.get('search') === ''
                ) && (
                    <Row style={{ marginTop: 20 }}>
                        <Col span='24'>
                            <SensorBotTable
                                network={network}
                                assets={assets}
                                searchParams={searchParams}
                                setSearchParams={setSearchParams}
                            />
                        </Col>
                    </Row>
                )}
                <Outlet />
            </>
        </>
    );
};

export default SensorBots;
