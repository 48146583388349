import * as MQTTMessager from '@accumine/mqtt-messager';

export default new MQTTMessager.Messager({
  url: process.env.REACT_APP_MQTT_URL,
  credentials: {
    username: process.env.REACT_APP_MQTT_USERNAME as string,
    password: process.env.REACT_APP_MQTT_PASSWORD as string,
  },
  protocol: parseInt(process.env.REACT_APP_MQTT_PROTOCOL as string),
  keepAlive: parseInt(process.env.REACT_APP_MQTT_KEEP_ALIVE as string),
  connectionTimeout: parseInt(
    process.env.REACT_APP_MQTT_CONNECTION_TIMEOUT as string
  ),
});
