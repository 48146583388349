import {
  NetworkNodeTemplate,
  NodeState,
} from '@accumine/xbee-network-manager/types';
import {
  Button,
  Collapse,
  Modal,
  Progress,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import {useEffect, useState} from 'react';
import {
  CheckCircleTwoTone,
  LoadingOutlined,
  PoweroffOutlined,
  QuestionCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';

const SensorBotStatus = ({
  sensorBot,
  iconSize,
}: {
  sensorBot: NetworkNodeTemplate;
  iconSize: number;
}) => {
  const [strength, setStrength] = useState(0);
  const [color, setColor] = useState('#434343');
  const [tooltip, setTooltip] = useState('Status unknown.');
  const [icon, setIcon] = useState(
    <QuestionCircleTwoTone style={{fontSize: iconSize}} />
  );
  const [suggestionsModalVisible, setSuggestionsModalVisible] = useState(false);
  const [suggestions, setSuggestions] = useState<
    {title: string; suggestion: string}[]
  >([]);

  useEffect(() => {
    let strength = 0,
      color = '#434343',
      icon = (
        <QuestionCircleTwoTone
          twoToneColor={color}
          style={{fontSize: iconSize}}
        />
      ),
      message;
    switch (sensorBot.state.v.state) {
      case NodeState.INITIALIZE:
        strength = 0;
        color = '#2f54eb';
        icon = (
          <LoadingOutlined twoToneColor={color} style={{fontSize: iconSize}} />
        );
        break;
      case NodeState.UPDATE:
        strength = sensorBot.state.v.progress;
        color = '#52c41a';
        icon = (
          <LoadingOutlined twoToneColor={color} style={{fontSize: iconSize}} />
        );
        break;
      case NodeState.OFFLINE:
        strength = 0;
        color = '#434343';
        icon = (
          <PoweroffOutlined twoToneColor={color} style={{fontSize: iconSize}} />
        );
        break;
      case NodeState.ONLINE:
        strength = 50;
        color = '#40a9ff';
        icon = (
          <CheckCircleTwoTone
            twoToneColor={color}
            style={{fontSize: iconSize}}
          />
        );
        break;
      case NodeState.SYNCHRONIZE:
        strength = 75;
        color = '#40a9ff';
        icon = (
          <CheckCircleTwoTone
            twoToneColor={color}
            style={{fontSize: iconSize}}
          />
        );
        break;
      case NodeState.STREAM:
        strength = 100;
        color = '#52c41a';
        icon = (
          <CheckCircleTwoTone
            twoToneColor={color}
            style={{fontSize: iconSize}}
          />
        );
        break;
      case NodeState.UNREACHABLE:
        strength = 25;
        color = '#faad14';
        icon = (
          <WarningTwoTone twoToneColor={color} style={{fontSize: iconSize}} />
        );
        break;
      case NodeState.UNSYNCABLE:
        strength = 25;
        color = '#faad14';
        icon = (
          <WarningTwoTone twoToneColor={color} style={{fontSize: iconSize}} />
        );
        break;
      case NodeState.UNHEALTHY:
        strength = 25;
        color = '#faad14';
        icon = (
          <WarningTwoTone twoToneColor={color} style={{fontSize: iconSize}} />
        );
        break;
      default:
        message = 'Status unknown.';
    }
    setStrength(strength);
    setColor(color);
    setIcon(icon);
    if (
      sensorBot.state.v.state === NodeState.UNREACHABLE ||
      sensorBot.state.v.state === NodeState.UNSYNCABLE
    ) {
      setTooltip(
        `${
          message ? message : sensorBot.state.v.description
        } Click for suggestions.`
      );
    } else {
      setTooltip(message ? message : sensorBot.state.v.description);
    }
  }, [sensorBot]);

  return (
    <>
      <Tooltip title={tooltip}>
        {sensorBot.state.v.state === NodeState.UNREACHABLE ||
        sensorBot.state.v.state === NodeState.UNSYNCABLE ? (
          <Button
            type="link"
            onClick={() =>
              window.open('https://www.accumine.com/faq/sensorbot')
            }
          >
            <Progress
              percent={strength}
              strokeColor={color}
              strokeWidth={20}
              steps={4}
              type="line"
              size="default"
              showInfo={true}
              format={(_) => icon}
            />
          </Button>
        ) : (
          <Progress
            style={
              sensorBot.state.v.state === NodeState.UPDATE
                ? {width: '100px'}
                : undefined
            }
            percent={strength}
            strokeColor={color}
            strokeWidth={20}
            steps={sensorBot.state.v.state === NodeState.UPDATE ? undefined : 4}
            type="line"
            size="default"
            showInfo={true}
            status={
              sensorBot.state.v.state === NodeState.UPDATE
                ? 'active'
                : undefined
            }
            format={(percent) =>
              sensorBot.state.v.state === NodeState.UPDATE
                ? `${percent}%`
                : icon
            }
          />
        )}
      </Tooltip>
      <Modal
        visible={suggestionsModalVisible}
        title={
          <Row>
            <Typography.Title level={4}>{'Suggested Actions'}</Typography.Title>
            <Typography.Title level={5} type="secondary">
              {''}
            </Typography.Title>
          </Row>
        }
        cancelButtonProps={{style: {display: 'none'}}}
        footer={null}
        onCancel={() => setSuggestionsModalVisible(false)}
        onOk={() => setSuggestionsModalVisible(false)}
      >
        <Collapse accordion>
          {suggestions.map((s, i) => {
            return (
              <Collapse.Panel header={s.title} key={i}>
                <p>{s.suggestion}</p>
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </Modal>
    </>
  );
};

export default SensorBotStatus;
