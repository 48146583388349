import {
  DeviceEvent,
  GatewayState,
  NetworkRootTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {
  BellTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
  HistoryOutlined,
  InfoCircleTwoTone,
  PlusSquareTwoTone,
  UserOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import {
  Col,
  Tooltip,
  Typography,
  Descriptions,
  Alert,
  Divider,
  Button,
  message,
  Popconfirm,
  Row,
  Spin,
  Space,
  Result,
  Input,
  Empty,
  Avatar,
} from 'antd';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useEffect, useState} from 'react';
import {Link, Outlet, useNavigate, useParams} from 'react-router-dom';
import {
  addEvent,
  getLastEvent,
  removeGateway,
  updateGateway,
} from '../../util/networkManager';
import MQTT from '../../util/mqtt';
import Modal from 'antd/lib/modal/Modal';
import { wrap } from '@accumine/xbee-network-manager/util';

dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const GatewayDetailsChild = ({
  network,
  gateway,
  mqtt,
}: {
  network: NetworkTemplate;
  gateway: NetworkRootTemplate;
  mqtt: typeof MQTT;
}) => {
  const navigate = useNavigate();
  const getUptime = (root: NetworkRootTemplate) => {
    let newUptime: string;
    if (root.state.v.state === GatewayState.OFFLINE) {
      newUptime = 'N/A';
    } else {
      newUptime =
        root.state.v.onlineOn !== -1
          ? dayjs.duration(dayjs().diff(dayjs(root.state.v.onlineOn))).humanize()
          : 'N/A';
    }
    return newUptime;
  };

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [lastNote, setLastNote] = useState<DeviceEvent | undefined>(undefined);
  const [newNote, setNewNote] = useState('');
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [uptime, setUptime] = useState(getUptime(gateway));

  const handleDisplayNameChange = async (displayName: string) => {
    try {
      gateway.displayName = wrap(displayName, 'cloud');
      await updateGateway(gateway);
      await addEvent({
        userId: localStorage.getItem('username') as string,
        deviceId: gateway.registeredId.v,
        type: 'event',
        description: `User modified Gateway name.`,
        timestamp: dayjs().utc().valueOf(),
      });
    } catch (error) {
      message.error(`Could not change nickname: ${error}`);
    }
  };

  const handleNoteAdd = async () => {
    setLoadingNotes(true);
    try {
      if (newNote !== '') {
        await addEvent({
          userId: localStorage.getItem('username') as string,
          deviceId: gateway.registeredId.v,
          type: 'note',
          description: newNote,
          timestamp: dayjs().utc().valueOf(),
        });
        setNewNote('');
        message.success('Note added.');
      }
    } catch (error) {
      message.error(`Could not add note: ${error}`);
    }
    setLoadingNotes(false);
  };

  const fetchEvent = async () => {
    let note = await getLastEvent(gateway.registeredId.v, 'note');
    if (note) {
      setLastNote(note);
    }
  };

  const handleNewEvent = (item: DeviceEvent) => {
    if (item.deviceId === gateway.registeredId.v && item.type === 'note') {
      if (!lastNote) {
        setLastNote(item);
      } else if (dayjs(item.timestamp) > dayjs(lastNote.timestamp)) {
        setLastNote(item);
      }
    }
  };

  useEffect(() => {
    fetchEvent();
  }, []);

  useEffect(() => {
    mqtt.router.on(
      `${localStorage['databaseId']}/${localStorage['deviceHistoryDataModelId']}/create`,
      handleNewEvent
    );
    return () => {
      mqtt.router.removeListener(
        `${localStorage['databaseId']}/${localStorage['deviceHistoryDataModelId']}/create`,
        handleNewEvent
      );
    };
  }, [lastNote]);

  useEffect(() => {
    setUptime(getUptime(gateway));

    let timer = setInterval(() => {
      setUptime(getUptime(gateway));
    }, 60000);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [gateway]);

  return (
    <>
      <Col
        span="19"
        style={{
          height: window.innerHeight * 0.75,
          padding: '10px 16px',
          backgroundColor: '#fff',
          overflow: 'auto',
          border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        <Descriptions
          title={
            <>
              <Typography.Text editable={{onChange: handleDisplayNameChange}}>
                {gateway.displayName.v}
              </Typography.Text>
            </>
          }
          layout="vertical"
          bordered
          size="small"
          extra={
            <Row>
              <Space>
                <Tooltip title={`Diagnostic information`}>
                  <Button
                    onClick={() => navigate('diagnostics')}
                    type="default"
                    icon={
                      <InfoCircleTwoTone
                        twoToneColor="#1890ff"
                        style={{fontSize: '20px'}}
                      />
                    }
                  ></Button>
                </Tooltip>
                {network.nodes.filter(
                  (n) => n.gatewayId.v === gateway.registeredId.v
                ).length > 0 ? (
                  <Tooltip
                    title={`This Gateway cannot be deleted until its ${
                      network.nodes.filter(
                        (n) => n.gatewayId.v === gateway.registeredId.v
                      ).length === 1
                        ? 'Sensorbot is'
                        : 'Sensorbots are'
                    } moved to another Gateway.`}
                  >
                    <Button
                      type="default"
                      danger
                      disabled
                      icon={
                        <DeleteTwoTone
                          twoToneColor="#ff4d4f"
                          style={{fontSize: '20px'}}
                        />
                      }
                    ></Button>
                  </Tooltip>
                ) : (
                  <Popconfirm
                    title="Are you sure?"
                    onVisibleChange={(visible) => setPopConfirmVisible(visible)}
                    onConfirm={async () => {
                      setLoadingDelete(true);
                      try {
                        await removeGateway(gateway);
                        message.success('Gateway deleted');
                        navigate(
                          `/gateways${
                            network.roots.length !== 0
                              ? '/' + network.roots[0].registeredId
                              : ''
                          }`
                        );
                      } catch (error) {
                        message.error(`Could not delete Gateway: ${error}`);
                      }
                      setLoadingDelete(false);
                    }}
                    onCancel={undefined}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Tooltip
                      visible={popConfirmVisible ? false : undefined}
                      title={`Delete gateway`}
                    >
                      <Button
                        type="default"
                        danger
                        loading={loadingDelete}
                        icon={
                          <DeleteTwoTone
                            twoToneColor="#ff4d4f"
                            style={{fontSize: '20px'}}
                          />
                        }
                      ></Button>
                    </Tooltip>
                  </Popconfirm>
                )}
              </Space>
            </Row>
          }
          column={7}
        >
          <Descriptions.Item label="MAC">
            <Typography.Text copyable={true}>
              {gateway.registeredId.v.toUpperCase()}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Transceiver Serial No.">
            <Typography.Text copyable={gateway.nodeId.v !== 'N/A'}>
              {gateway.nodeId.v.toUpperCase()}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Uptime">{uptime}</Descriptions.Item>
          <Descriptions.Item label="Registered On">
            {dayjs(gateway.registrationTimestamp).calendar()}
          </Descriptions.Item>
          <Descriptions.Item label="SensorBots">
            <Link to={`/sensorbots?page=1&search=${gateway.displayName.v}`}>
              {
                network.nodes.filter(
                  (n) => n.gatewayId.v === gateway.registeredId.v
                ).length
              }
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label="Assigned Load">
            <Tooltip
              title={`Estimated network load based on assigned Sensorbot settings`}
            >
              {Math.round(gateway.load.v * 100) / 100}%
            </Tooltip>
          </Descriptions.Item>
          <Descriptions.Item label="Version">
            {gateway.version.v}
          </Descriptions.Item>
        </Descriptions>
        <Divider style={{marginTop: 10, marginBottom: 10}} />
        {gateway.state.v.state === GatewayState.INITIALIZE && (
          <>
            <Alert
              message={gateway.state.v.recommendedAction}
              description={gateway.state.v.description}
              type="info"
              showIcon
              icon={
                <InfoCircleTwoTone
                  twoToneColor="#1890ff"
                  style={{fontSize: '30px'}}
                />
              }
            />
          </>
        )}

        {gateway.state.v.state === GatewayState.HEALTHY && (
          <>
            <Alert
              message={gateway.state.v.recommendedAction}
              description={gateway.state.v.description}
              type="success"
              showIcon
              icon={
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{fontSize: '30px'}}
                />
              }
            />
          </>
        )}

        {gateway.state.v.state === GatewayState.UNHEALTHY && (
          <>
            <Alert
              message={gateway.state.v.recommendedAction}
              description={gateway.state.v.description}
              type="error"
              showIcon
              icon={
                <WarningTwoTone
                  twoToneColor="#ff4d4f"
                  style={{fontSize: '30px'}}
                />
              }
            />
          </>
        )}

        {gateway.state.v.state === GatewayState.UPDATE && (
          <>
            <div style={{textAlign: 'center'}}>
              <Spin />
              <Typography.Title level={3}>
                Gateway is updating to a new software version.
              </Typography.Title>
            </div>
          </>
        )}

        {gateway.state.v.state === GatewayState.OFFLINE && (
          <>
            <Alert
              message={gateway.state.v.recommendedAction}
              description={gateway.state.v.description}
              type="error"
              showIcon
              icon={
                <CloseCircleTwoTone
                  twoToneColor="#ff4d4f"
                  style={{fontSize: '30px'}}
                />
              }
            />
          </>
        )}

        <Divider style={{marginTop: 10, marginBottom: 10}} />

        <Descriptions
          title={
            <Space>
              <Typography.Text>Latest note by</Typography.Text>
              {lastNote && lastNote.userId ? (
                <Space>
                  <Avatar icon={<UserOutlined />} />
                  {lastNote.userId.split('@')[0]}
                </Space>
              ) : null}
            </Space>
          }
          contentStyle={{marginBottom: 0}}
          layout="vertical"
          size="small"
          extra={
            <Space>
              <Typography.Text>
                {lastNote ? dayjs(lastNote.timestamp).format('LLLL') : ''}
              </Typography.Text>
              <Tooltip title={`Add new note.`}>
                <Button
                  type="default"
                  onClick={() => setAddNoteModal(true)}
                  icon={
                    <PlusSquareTwoTone
                      twoToneColor="#1890ff"
                      style={{fontSize: '20px'}}
                    />
                  }
                />
              </Tooltip>
              <Tooltip title={`Show full history.`}>
                <Button
                  type="default"
                  onClick={() => navigate('history')}
                  icon={
                    <HistoryOutlined
                      twoToneColor="#1890ff"
                      style={{fontSize: '20px'}}
                    />
                  }
                ></Button>
              </Tooltip>
            </Space>
          }
          column={1}
        >
          <Descriptions.Item>
            {lastNote ? (
              <Typography.Paragraph>
                {lastNote.description}
              </Typography.Paragraph>
            ) : (
              <Empty
                style={{width: '100%'}}
                description="No notes for this device yet."
              />
            )}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Outlet />
      <Modal
        visible={addNoteModal}
        width={window.innerWidth * 0.4}
        title="Add Note"
        onCancel={() => {
          setNewNote('');
          setAddNoteModal(false);
        }}
        onOk={() => {
          handleNoteAdd();
          setAddNoteModal(false);
        }}
        okText="Save"
        cancelText="Cancel"
        okButtonProps={{loading: loadingNotes}}
      >
        <Input.TextArea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          rows={4}
        />
      </Modal>
    </>
  );
};

const GatewayDetails = ({
  network,
  mqtt,
}: {
  network: NetworkTemplate;
  mqtt: typeof MQTT;
}) => {
  const params = useParams();
  const gateway = network.roots.find(
    (r) => r.registeredId.v === params.registeredId
  );
  return (
    <>
      {gateway ? (
        <GatewayDetailsChild network={network} gateway={gateway} mqtt={mqtt} />
      ) : (
        <Col span="19">
          <Result
            status="404"
            title="Gateway doesn't exist"
            subTitle="The gateway you've selected does not exist. Please select another."
          />
        </Col>
      )}
    </>
  );
};

export default GatewayDetails;
