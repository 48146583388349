import {
    BellTwoTone,
    ExclamationCircleOutlined,
    PlusCircleOutlined,
    PlusSquareTwoTone,
    SearchOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    List,
    message,
    Modal,
    PageHeader,
    Result,
    Row,
    Space,
    Tooltip,
    Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import {
    NetworkRootTemplate,
    NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import { MAX_DISCOVER_FPS } from '@accumine/xbee-network-manager/const';
import GatewayStatus from '../../components/GatewayStatus';
import MQTT from '../../util/mqtt';
import {
    Outlet,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { updateNetwork } from '../../util/networkManager';
import { wrap } from '@accumine/xbee-network-manager/util';

const ListItem = ({ gateway }: { gateway: NetworkRootTemplate }) => {
    const [active, setActive] = useState(false);
    return (
        <NavLink
            key={gateway.registeredId.v}
            to={`${gateway.registeredId.v}`}
            style={({ isActive }) => {
                setActive(isActive);
                return {};
            }}
        >
            <List.Item
                style={{
                    paddingLeft: active ? 5 : 10,
                    borderLeft: active ? '5px solid #1890ff' : 'none',
                }}
                actions={[
                    <div key='1'>
                        <GatewayStatus gateway={gateway} iconSize={30} />
                    </div>,
                ]}
            >
                <List.Item.Meta title={gateway.displayName.v} />
            </List.Item>
        </NavLink>
    );
};

const Gateways = ({
    mqtt,
    network,
}: {
    mqtt: typeof MQTT;
    network: NetworkTemplate;
}) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [discovery, setDiscovery] = useState(
        network.roots.some((root) => root.discovery.v)
    );
    const [discoveryRunning, setDiscoveryRunning] = useState(false);
    const [numNodes, setNumNodes] = useState(network.nodes.length);
    const location = useLocation();

    const handleSearch = (e: any) => {
        let search = e.target.value.toLowerCase();
        if (search) {
            setSearchParams({ search });
        } else {
            setSearchParams({});
        }
    };

    useEffect(() => {
        if (
            (location.pathname === '/gateways' ||
                location.pathname === '/gateways/') &&
            network.roots.length > 0
        ) {
            navigate(network.roots[0].registeredId.v);
        }
    });

    useEffect(() => {
        setDiscovery(network.roots.some((root) => root.discovery.v));
    }, [network]);

    useEffect(() => {
        if (discovery) {
            setNumNodes(network.nodes.length);
            setDiscoveryRunning(true);
        } else {
            setDiscoveryRunning(false);
        }
    }, [discovery]);

    useEffect(() => {
        if (!discovery && discoveryRunning) {
            message.success(
                `${network.nodes.length - numNodes} new SensorBots added.`
            );
        }
    }, [discovery, discoveryRunning, numNodes]);

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={undefined}
                title={`${localStorage['factoryId']} Gateways`}
                extra={
                    <Space align='center'>
                        <Input
                            key='search'
                            bordered={false}
                            style={{ width: 300 }}
                            prefix={<SearchOutlined />}
                            placeholder={`Search...`}
                            allowClear
                            onChange={handleSearch}
                            value={searchParams.get('search') || ''}
                        />
                        <Tooltip title={`Subscribe to notifications`}>
                            <Button
                                onClick={() => navigate('subscriptions')}
                                type='default'
                                icon={
                                    <BellTwoTone
                                        twoToneColor='#1890ff'
                                        style={{ fontSize: '20px' }}
                                    />
                                }
                            ></Button>
                        </Tooltip>
                        <Tooltip
                            title={`${
                                discovery ? 'Finding' : 'Find'
                            } new SensorBots.`}
                        >
                            <Button
                                type='default'
                                onClick={async () => {
                                    if (
                                        network.roots.some(
                                            (r) => r.fps.v >= MAX_DISCOVER_FPS
                                        )
                                    ) {
                                        Modal.confirm({
                                            title: 'Are you sure?',
                                            icon: <ExclamationCircleOutlined />,
                                            content: (
                                                <Typography.Text>
                                                    <p>
                                                        Some gateways on this
                                                        network have too much
                                                        traffic for discovering
                                                        new devices.
                                                    </p>
                                                    <p>Accumine will:</p>
                                                    <ul>
                                                        <li>
                                                            Temporarily reduce
                                                            traffic by disabling
                                                            some SensorBot
                                                            datastreams.
                                                        </li>
                                                        <li>
                                                            Try to reduce
                                                            traffic only on
                                                            SensorBots with
                                                            inputs that appear
                                                            off or change
                                                            slowly.
                                                        </li>
                                                        <li>
                                                            Interpolate lost
                                                            data if any
                                                            SensorBots run out
                                                            of buffer space.
                                                        </li>
                                                    </ul>
                                                </Typography.Text>
                                            ),
                                            onOk() {
                                                for (let root of network.roots) {
                                                    root.discovery = wrap(
                                                        true,
                                                        'cloud'
                                                    );
                                                }
                                                return updateNetwork(network);
                                            },
                                            onCancel() {},
                                            centered: true,
                                        });
                                    } else {
                                        for (let root of network.roots) {
                                            root.discovery = wrap(
                                                true,
                                                'cloud'
                                            );
                                        }
                                        await updateNetwork(network);
                                    }
                                }}
                                icon={
                                    <SyncOutlined
                                        spin={discovery}
                                        style={{ fontSize: '20px' }}
                                    />
                                }
                            />
                        </Tooltip>
                        <Tooltip title='Add a gateway.'>
                            <Button
                                type='default'
                                onClick={() => navigate('create')}
                                icon={
                                    <PlusSquareTwoTone
                                        twoToneColor='#1890ff'
                                        style={{ fontSize: '20px' }}
                                    />
                                }
                            />
                        </Tooltip>
                    </Space>
                }
            />

            {network.roots.length === 0 && searchParams.get('search') === '' ? (
                <Result
                    title='No Gateways Found'
                    subTitle='Add your first Gateway'
                    extra={[
                        <Button
                            type='primary'
                            key='add-first-gateway-button'
                            icon={<PlusCircleOutlined />}
                            onClick={() => navigate('create')}
                        >
                            Add Gateway
                        </Button>,
                    ]}
                />
            ) : (
                <Row style={{ marginTop: 20 }}>
                    <Col span='5'>
                        <div
                            style={{
                                height: window.innerHeight * 0.75,
                                overflow: 'auto',
                                backgroundColor: '#fff',
                            }}
                        >
                            <List
                                dataSource={network.roots.filter((g) => {
                                    return (
                                        g.displayName.v
                                            .toLowerCase()
                                            .includes(
                                                searchParams.get('search') || ''
                                            ) ||
                                        g.nodeId.v
                                            .toLowerCase()
                                            .includes(
                                                searchParams.get('search') || ''
                                            ) ||
                                        g.registeredId.v
                                            .toLowerCase()
                                            .includes(
                                                searchParams.get('search') || ''
                                            )
                                    );
                                })}
                                bordered={true}
                                renderItem={(gateway) => (
                                    <ListItem gateway={gateway} />
                                )}
                            />
                        </div>
                    </Col>
                    <Outlet />
                </Row>
            )}
        </>
    );
};

export default Gateways;
