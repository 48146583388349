import {DeleteTwoTone, EditOutlined, LoadingOutlined} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  message,
  Popconfirm,
  Result,
  Row,
  Space,
  Statistic,
  Timeline,
  Tooltip,
  Typography,
} from 'antd';
import axios from 'axios';
import dayjs, {Dayjs} from 'dayjs';
import {useEffect, useRef, useState} from 'react';
import {AccumineCloudAsset, State} from '../../interfaces';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import MQTT from '../../util/mqtt';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {NetworkTemplate} from '@accumine/xbee-network-manager/types';
import {removeAsset} from '../../util/networkManager';
dayjs.extend(relativeTime);
dayjs.extend(calendar);

const AssetDetails = ({
  assets,
  mqtt,
  network,
}: {
  assets: AccumineCloudAsset[];
  mqtt: typeof MQTT;
  network: NetworkTemplate;
}) => {
  const params = useParams();
  const asset = assets.find((a) => a.deviceId === params.deviceId);
  return (
    <>
      {asset ? (
        <AssetDetailsChild asset={asset} mqtt={mqtt} network={network} />
      ) : (
        <Col span="19">
          <Result
            status="404"
            title="Asset doesn't exist"
            subTitle="The asset you've selected does not exist. Please select another."
          />
        </Col>
      )}
    </>
  );
};

const AssetDetailsChild = ({
  asset,
  mqtt,
  network,
}: {
  asset: AccumineCloudAsset;
  mqtt: typeof MQTT;
  network: NetworkTemplate;
}) => {
  const navigate = useNavigate();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [activeStates, _setActiveStates] = useState<State[]>([]);
  const activeStatesRef = useRef(activeStates);
  const setActiveStates = (states: State[]) => {
    activeStatesRef.current = states;
    _setActiveStates(states);
  };

  const [partCountValues, _setPartCountValues] = useState<number[]>([]);
  const partCountValuesRef = useRef(partCountValues);
  const setPartCountValues = (values: number[]) => {
    partCountValuesRef.current = values;
    _setPartCountValues(values);
  };

  const [productionStatus, _setProductionStatus] = useState<{
    status: 'running' | 'down' | 'unknown';
    since: Dayjs;
  }>({
    status: 'unknown',
    since: dayjs(),
  });
  const productionStatusRef = useRef(productionStatus);
  const setProductionStatus = (data: any) => {
    productionStatusRef.current = {...data};
    _setProductionStatus({...data});
  };
  const [timeline, _setTimeline] = useState<
    {
      timestampLabel: string;
      value: string;
    }[]
  >([]);
  const timelineRef = useRef(timeline);
  const setTimeline = (data: any) => {
    timelineRef.current = data;
    _setTimeline(data);
  };

  const fetchActiveStates = async (states?: State[]) => {
    try {
      let _states: State[] = states || [];
      if (!states) {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/v2/historical/raw`,
          {
            query: {
              deviceId: asset.deviceId,
              timeEnd: null,
            },
          }
        );
        _states = response.data;
        setActiveStates(_states);
      } else {
        setActiveStates(_states);
      }
      const incycle = _states.find((s) => s.name === 'In-Cycle'),
        downtime = _states.find((s) => s.name === 'Downtime');

      if (incycle) {
        productionStatus.status = 'running';
        productionStatus.since = dayjs(incycle.timeStart);
      } else if (downtime) {
        productionStatus.status = 'down';
        productionStatus.since = dayjs(downtime.timeStart);
      } else {
        productionStatus.status = 'unknown';
        productionStatus.since = dayjs();
      }

      setProductionStatus({...productionStatus});
    } catch (error) {
      message.error(`${error}`);
      throw error;
    }
  };

  const handleMQTTHook = (state: State) => {
    if (state.deviceId !== asset.deviceId) {
      return;
    }
    let timelineObject = {
      timestampLabel: dayjs().calendar(),
      value: '',
    };
    if (state.timeEnd === null) {
      let _activeStates: State[] = activeStatesRef.current;
      const idx = _activeStates.findIndex((s) => s.name === state.name);
      if (idx >= 0) {
        _activeStates[idx] = state;
      } else {
        _activeStates.push(state);
      }
      fetchActiveStates(_activeStates);
    }

    if (state.name === 'Part Count') {
      let _partCountValues: number[] = partCountValuesRef.current;
      _partCountValues.push(state.value);
      setPartCountValues(_partCountValues);
      timelineObject.value = `${state.value} ${
        asset.productionUnit || 'part'
      }s produced`;
    } else if (state.name === 'In-Cycle') {
      timelineObject.value = `Started running`;
    } else if (state.name === 'Downtime') {
      timelineObject.value = `Stopped running`;
    } else {
      timelineObject.value = `${state.name} = ${state.value}`;
    }

    if (timelineRef.current.length > 50) {
      timelineRef.current.shift();
    }

    timelineRef.current.push(timelineObject);

    setTimeline([...timelineRef.current]);
  };

  const handleAssetRename = async (newName: string) => {
    try {
      if (asset.name !== newName) {
        await axios.patch(
          `${process.env.REACT_APP_API}/v1/devices/${asset._id}/rename`,
          {
            name: newName,
          }
        );
        message.success('Name updated.');
      }
    } catch (error) {
      message.error('Could not update name. Please try again.');
    }
  };

  useEffect(() => {
    fetchActiveStates();
    mqtt.router.on(
      `${localStorage['databaseId']}/Mirror/States/+/create`,
      handleMQTTHook
    );
    return () => {
      mqtt.router.removeListener(
        `${localStorage['databaseId']}/Mirror/States/+/create`,
        handleMQTTHook
      );
    };
  }, [asset]);

  return (
    <>
      <Col
        span="19"
        style={{
          height: window.innerHeight * 0.75,
          padding: '10px 16px',
          backgroundColor: '#fff',
          overflow: 'auto',
          border: '1px solid rgba(140, 140, 140, 0.35)',
        }}
      >
        <Descriptions
          style={{background: '#fff'}}
          title={
            <>
              <Typography.Text editable={{onChange: handleAssetRename}}>
                {asset.name}
              </Typography.Text>
            </>
          }
          layout="vertical"
          bordered
          size="small"
          extra={
            <Row>
              <Space>
                <Button
                  onClick={() => navigate('edit')}
                  type="default"
                  icon={
                    <EditOutlined
                      twoToneColor="#1890ff"
                      style={{fontSize: '20px'}}
                    />
                  }
                ></Button>

                {network.nodes.filter((n) => n.deviceId.v === asset.deviceId)
                  .length > 0 ? (
                  <Tooltip
                    title={`This Asset cannot be deleted until its ${
                      network.nodes.filter((n) => n.deviceId.v === asset.deviceId)
                        .length === 1
                        ? 'Sensorbot is'
                        : 'Sensorbots are'
                    } moved to another Asset.`}
                  >
                    <Button
                      type="default"
                      danger
                      disabled
                      icon={
                        <DeleteTwoTone
                          twoToneColor="#ff4d4f"
                          style={{fontSize: '20px'}}
                        />
                      }
                    ></Button>
                  </Tooltip>
                ) : (
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={async () => {
                      setLoadingDelete(true);
                      try {
                        await removeAsset(asset);
                        message.success('Asset deleted');
                        navigate('/assets');
                      } catch (error) {
                        message.error(`Could not delete Asset: ${error}`);
                      }
                      setLoadingDelete(false);
                    }}
                    onCancel={undefined}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="default"
                      danger
                      loading={loadingDelete}
                      icon={
                        <DeleteTwoTone
                          twoToneColor="#ff4d4f"
                          style={{fontSize: '20px'}}
                        />
                      }
                    ></Button>
                  </Popconfirm>
                )}
              </Space>
            </Row>
          }
          column={7}
        ></Descriptions>
        <div
          style={{
            height: window.innerHeight * 0.75,
            backgroundColor: '#fff',
          }}
        >
          <Row style={{textAlign: 'center', height: '100%'}}>
            <Col span="16">
              <Typography.Title type="secondary" level={3}>
                Production Status
              </Typography.Title>
              <Row>
                <Col span="12">
                  <Statistic
                    title="Status"
                    value={`${
                      productionStatus.status === 'running' ? 'Cycling' : 'Down'
                    } - ${productionStatus.since.calendar()}`}
                    precision={2}
                    valueStyle={{
                      color:
                        productionStatus.status === 'running'
                          ? '#52c41a'
                          : '#f5222d',
                    }}
                    prefix={
                      productionStatus.status === 'running' ? (
                        <LoadingOutlined />
                      ) : undefined
                    }
                  />
                </Col>
                <Col span="12">
                  <Statistic
                    title="Cycles"
                    value={partCountValues.reduce((a, b) => a + b, 0)}
                    precision={0}
                  />
                </Col>
              </Row>
              <Divider />
              <Typography.Title type="secondary" level={3}>
                Other Outputs
              </Typography.Title>
              <Row
                style={{overflow: 'auto', height: window.innerHeight * 0.45}}
              >
                {activeStates
                  .filter(
                    (state) =>
                      !['In-Cycle', 'Downtime', 'Part Count'].includes(
                        state.name
                      )
                  )
                  .map((state) => {
                    console.log(state);
                    return (
                      <Col
                        key={`state-col-${state.name}`}
                        span="12"
                        style={{padding: 20}}
                      >
                        <Card
                          title={state.name}
                          actions={[
                            <div key={`state-timestamp-${state.name}`}>
                              {dayjs(state.timeStart).calendar()}
                            </div>,
                          ]}
                        >
                          <Typography.Title level={5}>
                            {state.value ? state.value.toString() : ''}
                          </Typography.Title>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
            <Col span="8" style={{borderLeft: '1px solid rgba(0, 0, 0, 0.06)'}}>
              <Typography.Title type="secondary" level={3}>
                Activity Timeline
              </Typography.Title>
              <Timeline
                mode="left"
                reverse={true}
                pending={<>Listening for data...</>}
                style={{
                  paddingTop: 5,
                  overflow: 'auto',
                  height: window.innerHeight * 0.65,
                }}
              >
                {timeline.map((row, i) => {
                  return (
                    <Timeline.Item label={row.timestampLabel} key={i}>
                      {row.value}
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </Col>
          </Row>
        </div>
      </Col>
      <Outlet />
    </>
  );
};

export default AssetDetails;
