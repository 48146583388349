import {
  NetworkNodeTemplate,
  NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import {Button, Col, Menu, Modal, Result, Row, Typography} from 'antd';
import {useState} from 'react';

import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {Line} from '@ant-design/charts';
import {useNavigate, useParams} from 'react-router-dom';
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(duration);

const SensorBotDiagnosticsChild = ({
  sensorBot,
}: {
  sensorBot: NetworkNodeTemplate;
}) => {
  const navigate = useNavigate();
  const [menuKey, setMenuKey] = useState<number>(0);

  return (
    <Modal
      visible={true}
      title="Diagnostic Information"
      cancelButtonProps={{style: {display: 'none'}}}
      onCancel={() => navigate(-1)}
      onOk={() => navigate(-1)}
      footer={null}
      width={window.innerWidth * 0.5}
    >
      <Menu theme="light" mode="horizontal" defaultSelectedKeys={['0']}>
        <Menu.Item key="0" onClick={() => setMenuKey(0)}>
          Round Trip Times
        </Menu.Item>
      </Menu>
      <div
        style={{
          height: window.innerHeight * 0.55,
          overflow: 'auto',
        }}
      >
        {
          {
            0: (
              <Row
                style={{
                  width: '100%',
                  marginTop: 10,
                }}
                gutter={[16, 24]}
              >
                <Col span={12}>
                  <Typography.Text
                    strong
                    style={{
                      fontSize: 20,
                      float: 'left',
                    }}
                  >
                    Round Trip Times
                  </Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text
                    style={{
                      float: 'right',
                    }}
                    type="secondary"
                  >{`Last updated ${dayjs(
                    sensorBot.rtts.v[sensorBot.rtts.v.length - 1].timestamp
                  ).toNow(true)} ago`}</Typography.Text>
                </Col>
                <Col span={24}>
                  <Line
                    height={window.innerHeight * 0.45}
                    animation={false}
                    padding="auto"
                    xField="timestamp"
                    yField="value"
                    yAxis={{
                      label: {
                        formatter: (v) => `${v}ms`,
                      },
                    }}
                    xAxis={{
                      label: {
                        formatter: (v) => dayjs(v).format('hh:mm:ss A'),
                      },
                    }}
                    data={sensorBot.rtts.v.map((item) => {
                      return {
                        value: item.rtt,
                        timestamp: dayjs(item.timestamp).toISOString(),
                      };
                    })}
                  />
                </Col>
              </Row>
            ),
          }[menuKey]
        }
      </div>
    </Modal>
  );
};

const SensorBotDiagnostics = ({network}: {network: NetworkTemplate}) => {
  const navigate = useNavigate();
  const params = useParams();
  const sensorBot = network.nodes.find((n) => n.nodeId.v === params.nodeId);
  return (
    <>
      {sensorBot ? (
        <SensorBotDiagnosticsChild sensorBot={sensorBot} />
      ) : (
        <Modal
          visible={true}
          title="Diagnostic Information"
          okButtonProps={{style: {display: 'none'}}}
          cancelButtonProps={{style: {display: 'none'}}}
        >
          <Result
            status="404"
            title="SensorBot doesn't exist"
            subTitle="The SensorBot you've selected does not exist."
            extra={
              <Button
                type="primary"
                onClick={() => navigate('/sensorbots?page=1')}
              >
                View all SensorBots
              </Button>
            }
          />
        </Modal>
      )}
    </>
  );
};

export default SensorBotDiagnostics;
