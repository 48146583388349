import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, List, PageHeader, Result, Row } from 'antd';
import { useEffect, useState } from 'react';
import { NetworkTemplate } from '@accumine/xbee-network-manager/types';
import MQTT from '../../util/mqtt';
import {
    Outlet,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AccumineCloudAsset } from '../../interfaces';

const ListItem = ({ asset }: { asset: AccumineCloudAsset }) => {
    const [active, setActive] = useState(false);
    return (
        <NavLink
            key={asset.deviceId}
            to={`${asset.deviceId}`}
            style={({ isActive }) => {
                setActive(isActive);
                return {};
            }}
        >
            <List.Item
                style={{
                    paddingLeft: active ? 5 : 10,
                    borderLeft: active ? '5px solid #1890ff' : 'none',
                }}
            >
                <List.Item.Meta title={asset.name} />
            </List.Item>
        </NavLink>
    );
};

const Assets = ({
    mqtt,
    network,
    assets,
}: {
    mqtt: typeof MQTT;
    network: NetworkTemplate;
    assets: AccumineCloudAsset[];
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();

    const handleSearch = (e: any) => {
        let search = e.target.value.toLowerCase();
        if (search) {
            setSearchParams({ search });
        } else {
            setSearchParams({});
        }
    };

    useEffect(() => {
        if (
            (location.pathname === '/assets' ||
                location.pathname === '/assets/') &&
            assets.length > 0
        ) {
            navigate(assets[0].deviceId);
        }
    });
    return (
        <>
            <PageHeader
                ghost={false}
                onBack={undefined}
                title={`${localStorage['factoryId']} Assets`}
                extra={[
                    <Input
                        key='search'
                        bordered={false}
                        style={{ width: 300 }}
                        prefix={<SearchOutlined />}
                        placeholder={`Search...`}
                        allowClear
                        onChange={handleSearch}
                        value={searchParams.get('search') || ''}
                    />,
                    <Button
                        key='add-asset-button'
                        type='primary'
                        icon={<PlusCircleOutlined />}
                        onClick={() => navigate('create')}
                    >
                        Add Asset
                    </Button>,
                ]}
            />
            {network.roots.length === 0 && searchParams.get('search') === '' ? (
                <Result
                    title='No Assets Found'
                    subTitle='Add your first Asset'
                    extra={[
                        <Button
                            type='primary'
                            key='add-first-asset-button'
                            icon={<PlusCircleOutlined />}
                            onClick={() => navigate('create')}
                        >
                            Add Asset
                        </Button>,
                    ]}
                />
            ) : (
                <Row style={{ marginTop: 20 }}>
                    <Col span='5'>
                        <div
                            style={{
                                height: window.innerHeight * 0.75,
                                overflow: 'auto',
                                backgroundColor: '#fff',
                            }}
                        >
                            <List
                                dataSource={assets.filter((a) => {
                                    return (
                                        a.name
                                            .toLowerCase()
                                            .includes(
                                                searchParams.get('search') || ''
                                            ) ||
                                        a.deviceId
                                            .toLowerCase()
                                            .includes(
                                                searchParams.get('search') || ''
                                            ) ||
                                        (a.groups &&
                                            a.groups.length &&
                                            a.groups[0]
                                                .toLowerCase()
                                                .includes(
                                                    searchParams.get(
                                                        'search'
                                                    ) || ''
                                                ))
                                    );
                                })}
                                bordered={true}
                                renderItem={(asset) => (
                                    <ListItem asset={asset} />
                                )}
                            />
                        </div>
                    </Col>
                    <Outlet />
                </Row>
            )}
        </>
    );
};

export default Assets;
