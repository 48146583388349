import {
    GatewayState,
    NetworkTemplate,
} from '@accumine/xbee-network-manager/types';
import { wrap } from '@accumine/xbee-network-manager/util';
import { Image, Form, Input, Modal, Typography, message } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addGateway } from '../../util/networkManager';
import MacAddressImage from '../../images/gateway-mac.png';

const AddGatewayModal = ({ network }: { network: NetworkTemplate }) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true);
        let formData: { macAddress: string; nickname: string } = {
            macAddress: '',
            nickname: '',
        };
        try {
            formData = await form.validateFields();
            formData.macAddress = formData.macAddress
                .replace(/[^a-zA-Z0-9 ]/g, '')
                .toLowerCase();
            await addGateway({
                registeredId: wrap(formData.macAddress, 'cloud'),
                displayName: wrap(formData.nickname, 'cloud'),
                registrationTimestamp: dayjs().utc().valueOf(),
                nodeId: wrap('N/A', 'cloud'),
                state: wrap(
                    {
                        state: GatewayState.INITIALIZE,
                        description: 'Waiting for gateway to connect.',
                        progress: 0,
                        recommendedAction:
                            'Plug in and configure the gateway using the Accumine CLI.',
                        errors: [],
                        numConsecutiveErrors: 0,
                        numErrorSinceRestart: 0,
                        onlineOn: -1,
                    },
                    'cloud'
                ),
                version: wrap('N/A', 'cloud'),
                networkId: wrap('0x7FFF', 'cloud'),
                databaseId: wrap(
                    localStorage.getItem('databaseId') as string,
                    'cloud'
                ),
                edges: [],
                fps: wrap(0, 'cloud'),
                load: wrap(0, 'cloud'),
                discovery: wrap(false, 'cloud'),
            });
            navigate(`/gateways/${formData.macAddress}`);
        } catch (error) {
            message.error(`Error: ${error}`);
        }
        setLoading(false);
    };
    return (
        <Modal
            visible={true}
            width={500}
            title='Add Gateway'
            cancelButtonProps={{ style: { display: 'none' } }}
            okText='Submit'
            onOk={submit}
            okButtonProps={{ loading }}
            onCancel={() => navigate(-1)}>
            <Form form={form} name='login' layout='vertical'>
                <div style={{ textAlign: 'center' }}>
                    <Typography.Text>
                        Locate the MAC Address Label on the Gateway:
                    </Typography.Text>
                    <Image
                        preview={false}
                        src={MacAddressImage}
                        height='200px'
                    />
                </div>
                <Form.Item
                    label='MAC Address'
                    name='macAddress'
                    rules={[
                        {
                            required: true,
                            message: 'MAC Address is required',
                        },
                        ({}) => ({
                            validator(_, value) {
                                if (
                                    network.roots.some(
                                        (root) => root.registeredId.v === value
                                    )
                                ) {
                                    return Promise.reject(
                                        new Error('MAC address must be unique.')
                                    );
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label='Nickname'
                    name='nickname'
                    rules={[
                        {
                            required: true,
                            message: 'Nickname is required',
                        },
                        ({}) => ({
                            validator(_, value) {
                                if (
                                    network.roots.some(
                                        (root) => root.displayName.v === value
                                    )
                                ) {
                                    return Promise.reject(
                                        new Error('Nickname must be unique.')
                                    );
                                } else {
                                    return Promise.resolve();
                                }
                            },
                        }),
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddGatewayModal;
